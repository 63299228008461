import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { NavLink } from "react-router-dom";
import { Box, Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const data = require('./../data/footer.json');

const logo = require("../images/full_logo.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  box: {
    marginTop: "5px"
  },
  second: {
    marginTop:"10px"
  },
  elements: {
    marginTop: "5px",
    fontSize:"80%"
  }
});

const elements_footer = [
  [
    ["Офіційний сайт НАЗК", "https://nazk.gov.ua/uk/"],
    ["Законодавство України", "https://zakon.rada.gov.ua/laws"],
    ["IFES в Україні", "https://www.ifes.org/ukraine"]
  ],
  [
    ["Відкритий портал фінансової звітності"],
    ["Розроблено в Україні, компанія Кітсофт, 2020"],
    ["Проект здійснений за фінансової підтримки міжнародних фондів"]
  ]
]

class ButtomBar extends Component {
  state = {
    value: 0,
    menuDrawer: false
  };


  render() {
    const { classes } = this.props;

    return null;

    return(<div className={classes.root}>
      <Container maxWidth="xl" className={classes.box}>
        <Grid container spacing={3}>
          <Grid item xs-4>
          <span>Контакт-центр: (044) 200 06 94, support@nazk.gov.ua</span>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs-2>
          <span></span>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs-6>
          <span>@{ new Date().getFullYear() } Національного агентства з питань запобігання корупції </span>
          <span> Розроблено в Україні, компанія Кітсофт </span>
          <span> Проект здійснений за підтримки Міжнародної фундації виборчих систем (IFES) та Проєкту USAID «Підтримка організацій-лідерів у протидії корупції в Україні «ВзаємоДія!» </span>
          </Grid>
        </Grid>
        </Container>
      </div>)

    return (
    <div className={classes.root}>
    <Container maxWidth="xl" className={classes.box}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper elevation={0} >
              <div className={classes.inline}>
                <Typography variant="h6" color="inherit">
                  <NavLink to="/" className={classes.NavLink}>
                    <img width={280} src={logo} alt="" />
                  </NavLink>
                </Typography>
              </div>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper elevation={0} className={classes.second}>
            {data.main_links.map((el, index_bt)=>{ 
                if(el.url != '') {
                  return <div key={index_bt} className={classes.elements}><a href={el.url}>{el.text}</a></div>
                } else {
                  return <div key={index_bt} className={classes.elements}>{el.text}</div>
                } 
            } )}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper elevation={0} className={classes.second}>
            {data.about.map(el=>{ 
                if(el.url != '') {
                  return <div className={classes.elements}><a href={el.url}>{el.text}</a></div>
                } else {
                  return <div className={classes.elements}>{el.text}</div>
                } 
            } )}
          </Paper>
        </Grid>
      </Grid>
      </Container>
    </div>
    );
  }
}

export default withStyles(styles)(ButtomBar);
