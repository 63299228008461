import React from 'react';

import { Header } from '../header';
import { ContentAbout } from './content';
import Footer from '../Footer';

export function About(props){
    return (<div>
        <Header short={true} full={false}/>
        <ContentAbout />
        <Footer />
      </div>
      )
}