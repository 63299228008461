
const params = require('./../../../conf/api.json');

const paramApi = params.paramApi;

let isFetching = false;

const url="/api/getallreportsshort";

const url_record="/api/getreportelection/:id";

const url_attach="/api/getreportattach/:id";

const test_data_report = {
    "data": {
      "date": "2023-03-06T13:30:45.735Z",
      "isCVK": true,
      "isTVK": false,
      "PDFfile": {
        "url": "https://plink.stage.politdata.nazk.gov.ua/30333737393830302d626332332d313165642d623237372d3939653064386233303565327307efbfbdefbfbd",
        "name": "Звіт про надходження та використання коштів виборчого фонду.pdf",
        "type": "application/pdf"
      },
      "initiator": "Розпорядник",
      "documentId": "258f88b0-bc07-11ed-9f28-cd27a23c5155",
      "electionDate": "02.03.2023",
      "reportNumber": "162",
      "typeReportFinal": true,
      "typeElectionNext": true,
      "contributionWrong": [
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Порошенко Р.Д.",
          "totalSum": 25000,
          "docNumber": "628JJ/88",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2700",
          "useLivingPlace": "Одеська область",
          "purposeOfPayment": "Оплата послуг",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Турчинов С.П.",
          "totalSum": 25000,
          "docNumber": "HH231",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2700",
          "useLivingPlace": "Дніпропетровська область",
          "purposeOfPayment": "Оплата послуг",
          "unzr": ""
        }
      ],
      "typeElectionFirst": false,
      "candidateIndividual": true,
      "candidateLegalEntity": false,
      "isDeputiesVrOfCrimea": false,
      "partyLocationCountry": "Україна",
      "typeElectionRepeated": false,
      "isDeputiesCityCouncil": false,
      "peopleDeputyElections": false,
      "presidentialElections": true,
      "typeElectionAdditional": false,
      "typeReportIntermediate": false,
      "candidateIsLocationSame": "Так",
      "candidateLocationRegion": "м.Київ",
      "candidateLocationStreet": "вул. Урлівська",
      "candidatePoliticalParty": false,
      "candidateLocationCountry": "Україна",
      "partyLocationCountryFact": "Україна",
      "typeElectionIntermediate": false,
      "candidateLocationBuilding": "12",
      "candidateLocationDistrict": "Дарницький",
      "contributionWrongAllMoney": 50000,
      "isDeputiesDistrictCouncil": false,
      "isDeputiesRegionalCouncil": false,
      "moneyElectionFundAccounts": [
        {
          "naming": "ПАТ КБ \"ГЛОБУС\", 23157896, м. Київ",
          "totalSum": 2500000,
          "accNumber": "UA033805260000002600800159741",
          "leftMoney": 0,
          "totalUsed": 2500000,
          "typeOfAcc": "Накопичувальний",
          "regionNumber": "413"
        },
        {
          "naming": "ПАТ КБ \"ГЛОБУС\", 23157896, м. Київ",
          "totalSum": 2000000,
          "accNumber": "UA363805260000029093001059741",
          "leftMoney": 0,
          "totalUsed": 2000000,
          "typeOfAcc": "Поточний",
          "regionNumber": "413"
        },
        {
          "naming": "ПАТ КБ \"ГЛОБУС\", 23157896, м. Київ",
          "totalSum": 500000,
          "accNumber": "UA943805260000026006002059741",
          "leftMoney": 0,
          "totalUsed": 500000,
          "typeOfAcc": "Поточний",
          "regionNumber": "413"
        }
      ],
      "typeElectionExtraordinary": false,
      "contributionVoluntaryFizUr": [
        {
          "sum": 25000,
          "rnokpp": "3216549870",
          "userFIO": "Гринько І.П.",
          "totalSum": 25000,
          "docNumber": "618/88",
          "dateIncome": "20.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "06.03.2005",
          "useLivingPlace": "м. Київ"
        },
        {
          "sum": 750000,
          "rnokpp": "21356980",
          "userFIO": "АТ \"Рогань\"",
          "totalSum": 750000,
          "userType": [
            "Юридична особа"
          ],
          "docNumber": "31121",
          "dateIncome": "21.02.2023",
          "articleCode": "2400",
          "useLivingPlace": "м. Київ"
        },
        {
          "sum": 25000,
          "rnokpp": "2154698732",
          "userFIO": "Теренев І.Р.",
          "totalSum": 25000,
          "docNumber": "618",
          "dateIncome": "20.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "04.08.2004",
          "useLivingPlace": "м. Вінниця"
        },
        {
          "sum": 25000,
          "rnokpp": "3212165471",
          "userFIO": "Приходько І.В.",
          "totalSum": 25000,
          "docNumber": "618/33",
          "dateIncome": "20.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "27.03.1980",
          "useLivingPlace": "м. Суми"
        },
        {
          "sum": 25000,
          "rnokpp": "2564165483",
          "userFIO": "Таранько О.Д.",
          "totalSum": 25000,
          "docNumber": "622/88",
          "dateIncome": "20.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "11.03.1999",
          "useLivingPlace": "м. Київ"
        },
        {
          "sum": 25000,
          "rnokpp": "2199821654",
          "userFIO": "Прилукин Р.Л.",
          "totalSum": 25000,
          "docNumber": "699",
          "dateIncome": "21.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "17.06.2004",
          "useLivingPlace": "м. Дніпро"
        },
        {
          "sum": 25000,
          "rnokpp": "3451165487",
          "userFIO": "Смаглюк О.Л.",
          "totalSum": 25000,
          "docNumber": "688/22",
          "dateIncome": "22.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "22.03.1969",
          "useLivingPlace": "м. Одеса"
        },
        {
          "sum": 25000,
          "rnokpp": "2132165487",
          "userFIO": "Дубинський О.С.",
          "totalSum": 25000,
          "docNumber": "911/11",
          "dateIncome": "22.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "06.03.1990",
          "useLivingPlace": "м. Київ"
        },
        {
          "sum": 25000,
          "rnokpp": "3112165487",
          "userFIO": "Тараненко Т.П.",
          "totalSum": 25000,
          "docNumber": "61369GG",
          "dateIncome": "23.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "24.11.1982",
          "useLivingPlace": "м. Одеса"
        },
        {
          "sum": 25000,
          "rnokpp": "2232165936",
          "userFIO": "Турчинов С.П.",
          "totalSum": 25000,
          "docNumber": "HH231",
          "dateIncome": "24.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "10.07.1945",
          "useLivingPlace": "м. Дніпро"
        },
        {
          "sum": 25000,
          "rnokpp": "2916548794",
          "userFIO": "Порошенко Р.Д.",
          "totalSum": 25000,
          "docNumber": "628JJ/88",
          "dateIncome": "24.02.2023",
          "articleCode": "2300",
          "dateOfBirth": "10.06.1952",
          "useLivingPlace": "м. Одеса"
        }
      ],
      "isCandidateDeputyElections": false,
      "isDeputiesPositionCityHead": false,
      "receivingOnCurrentAccounts": [
        {
          "sum": 1000000,
          "rnokpp": "**********",
          "userFIO": "Гудкович Д.В.",
          "totalSum": 1000000,
          "docNumber": "1/23",
          "dateIncome": "22.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2500",
          "regionNumber": "413",
          "typeOfIncome": "Перерахування коштів з накопичувального рахунку",
          "contractDetails": "1",
          "userLivingPlace": "м. Київ",
          "purposeOfPayment": "Поповнення поточного рухунку виборчого фонду",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Порошенко Р.Д.",
          "totalSum": 25000,
          "docNumber": "628JJ/88",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2700",
          "regionNumber": "413",
          "typeOfIncome": "помилкові надходження коштів ",
          "contractDetails": "б/н",
          "userLivingPlace": "Одеська область",
          "purposeOfPayment": "Оплата за господарським договором",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Турчинов С.П.",
          "totalSum": 25000,
          "docNumber": "HH231",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2700",
          "regionNumber": "413",
          "typeOfIncome": "помилкові надходження коштів ",
          "contractDetails": "б/н",
          "userLivingPlace": "Дніпропетровська область",
          "purposeOfPayment": "Оплата оренди приміщення",
          "unzr": ""
        },
        {
          "sum": 44000,
          "rnokpp": "********",
          "userFIO": "ТОВ \"Промінь\"",
          "totalSum": 44000,
          "docNumber": "36/19",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "2700",
          "regionNumber": "413",
          "typeOfIncome": "помилкові надходження коштів ",
          "contractDetails": "б/н",
          "userLivingPlace": "Київська область",
          "purposeOfPayment": "Оплата за господарським договором",
          "unzr": ""
        },
        {
          "sum": 3600,
          "rnokpp": "**********",
          "userFIO": "Крапива А.М.",
          "totalSum": 3600,
          "docNumber": "36/20",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2700",
          "regionNumber": "413",
          "typeOfIncome": "помилкові надходження коштів ",
          "contractDetails": "б/н",
          "userLivingPlace": "Київська область",
          "purposeOfPayment": "Оплата реклами в ЗМІ",
          "unzr": ""
        },
        {
          "sum": 50000,
          "rnokpp": "*********",
          "userFIO": "Жмих Ю.Б.",
          "totalSum": 50000,
          "docNumber": "36/21",
          "dateIncome": "24.02.2023",
          "typeOfUser": "Фізична ¾ÑÐ¾Ð±Ð°",
          "articleCode": "2700",
          "regionNumber": "413",
          "typeOfIncome": "Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ñ Ð½Ð°Ð´ÑÐ¾Ð´Ð¶ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² ",
          "contractDetails": "Ð±/Ð½",
          "userLivingPlace": "Ð§ÐµÑÐºÐ°ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð³Ð¾ÑÐ¿Ð¾Ð´Ð°ÑÑÑÐºÐ¸Ð¼ Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": ""
        },
        {
          "sum": 1500000,
          "rnokpp": "**********",
          "userFIO": "ÐÑÐ´ÐºÐ¾Ð²Ð¸Ñ Ð.Ð.",
          "totalSum": 1500000,
          "docNumber": "1/23",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "2500",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐµÑÐµÑÐ°ÑÑÐ²Ð°Ð½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð· Ð½Ð°ÐºÐ¾Ð¿Ð¸ÑÑÐ²Ð°Ð»ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ",
          "contractDetails": "133",
          "userLivingPlace": "Ð¼. ÐÐ¸ÑÐ²",
          "purposeOfPayment": "ÐÐ¾Ð¿Ð¾Ð²Ð½ÐµÐ½Ð½Ñ Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÑÑÑÐ½ÐºÑ Ð²Ð¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÑÐ¾Ð½Ð´Ñ",
          "unzr": ""
        }
      ],
      "candidateWithoutLegalEntity": false,
      "isIdCardCandidateIndividual": false,
      "candidateLocationCountryFact": "Ð£ÐºÑÐ°ÑÐ½Ð°",
      "contributionOwnCandidateMoney": [
        {
          "sum": 1100000,
          "totalSum": 1100000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "213",
          "dateIncome": "21.02.2023",
          "articleCode": "2100",
          "candidateFIO": "ÐÐ \"ÐÑÑÑÑÐ½Ðµ ÑÑÐ¹Ð²Ð¾\"",
          "typeOfIncome": "ÐÐ»Ð°ÑÐ½Ñ ÐºÐ¾ÑÑÐ¸ Ð¿Ð¾Ð»ÑÑÐ¸ÑÐ½Ð¾Ñ Ð¿Ð°ÑÑÑÑ, ÑÐºÐ° Ð²Ð¸ÑÑÐ½ÑÐ»Ð° ÐºÐ°Ð½Ð´Ð¸Ð´Ð°ÑÐ° Ð² ÐÑÐµÐ·Ð¸Ð´ÐµÐ½ÑÐ¸ Ð£ÐºÑÐ°ÑÐ½Ð¸"
        },
        {
          "sum": 400000,
          "totalSum": 400000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "413/81",
          "dateIncome": "22.02.2023",
          "articleCode": "2200",
          "candidateFIO": "ÐÑÐ¸Ð¼Ð°ÑÐµÐ½ÐºÐ¾ Ð.Ð.",
          "typeOfIncome": "ÐÐ»Ð°ÑÐ½Ñ ÐºÐ¾ÑÑÐ¸ ÐºÐ°Ð½Ð´Ð¸Ð´Ð°ÑÐ° Ð² ÐÑÐµÐ·Ð¸Ð´ÐµÐ½ÑÐ¸ Ð£ÐºÑÐ°ÑÐ½Ð¸"
        }
      ],
      "isCandidateDeputiesVrOfCrimea": false,
      "isDeputiesDistrictCityCouncil": false,
      "isPassportCandidateIndividual": false,
      "isCandidateDeputiesCityCouncil": false,
      "refundsMoneyFromCurrentAccount": [
        {
          "sum": 44000,
          "rnokpp": "********",
          "userFIO": "Ð¢ÐÐ \"ÐÑÐ¾Ð¼ÑÐ½Ñ\"",
          "totalSum": 44000,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "36/19",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð®ÑÐ¸Ð´Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "1",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð· Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ Ð²Ð¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÑÐ¾Ð½Ð´Ñ",
          "userLivingPlace": "ÐÐ¸ÑÐ²ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð· Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ",
          "unzr": ""
        },
        {
          "sum": 3600,
          "rnokpp": "**********",
          "userFIO": "ÐÑÐ°Ð¿Ð¸Ð²Ð° Ð.Ð.",
          "totalSum": 3600,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "36/20",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "4",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð½Ð° ÑÐ°ÑÑÐ½Ð¾Ðº Ð²ÑÐ´Ð¿Ð¾Ð²ÑÐ´Ð½Ð¾Ð³Ð¾ ÑÐ¾Ð½Ð´Ñ ÐºÐ¾ÑÑÑÐ², Ð¿ÐµÑÐµÑÐ°ÑÐ¾Ð²Ð°Ð½Ð¸Ñ Ð²Ð¸ÐºÐ¾Ð½Ð°Ð²ÑÑÐ¼",
          "userLivingPlace": "ÐÐ¸ÑÐ²ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð²Ð¸ÐºÐ¾Ð½Ð°Ð²ÑÑ Ð· Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ",
          "unzr": ""
        },
        {
          "sum": 50000,
          "rnokpp": "*********",
          "userFIO": "ÐÐ¼Ð¸Ñ Ð®.Ð.",
          "totalSum": 50000,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "36/21",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "3700",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¸Ñ Ð½Ð°Ð´ÑÐ¾Ð´Ð¶ÐµÐ½Ñ ÐºÐ¾ÑÑÑÐ²",
          "userLivingPlace": "Ð§ÐµÑÐºÐ°ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¾ Ð·Ð°ÑÐ°ÑÐ¾Ð²Ð°Ð½Ð¸Ñ ÐºÐ¾ÑÑÑÐ²",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "ÐÐ¾ÑÐ¾ÑÐµÐ½ÐºÐ¾ Ð .Ð.",
          "totalSum": 25000,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "37/1",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "3700",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¸Ñ Ð½Ð°Ð´ÑÐ¾Ð´Ð¶ÐµÐ½Ñ ÐºÐ¾ÑÑÑÐ²",
          "userLivingPlace": "ÐÐ´ÐµÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¾ Ð·Ð°ÑÐ°ÑÐ¾Ð²Ð°Ð½Ð¸Ñ ÐºÐ¾ÑÑÑÐ²",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Ð¢ÑÑÑÐ¸Ð½Ð¾Ð² Ð¡.Ð.",
          "totalSum": 25000,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "37/2",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "3700",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¸Ñ Ð½Ð°Ð´ÑÐ¾Ð´Ð¶ÐµÐ½Ñ ÐºÐ¾ÑÑÑÐ²",
          "userLivingPlace": "ÐÐ½ÑÐ¿ÑÐ¾Ð¿ÐµÑÑÐ¾Ð²ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ Ð¿Ð¾Ð¼Ð¸Ð»ÐºÐ¾Ð²Ð¾ Ð·Ð°ÑÐ°ÑÐ¾Ð²Ð°Ð½Ð¸Ñ ÐºÐ¾ÑÑÑÐ²",
          "unzr": ""
        },
        {
          "sum": 1000000,
          "rnokpp": "**********",
          "userFIO": "ÐÑÐ´ÐºÐ¾Ð²Ð¸Ñ Ð.Ð.",
          "totalSum": 1000000,
          "accNumber": "UA363805260000029093001059741",
          "docNumber": "339",
          "dateIncome": "03.03.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "1",
          "contractInfo": "Ð±/Ð½",
          "regionNumber": "413",
          "typeOfIncome": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð· Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ Ð²Ð¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÑÐ¾Ð½Ð´Ñ",
          "userLivingPlace": "Ð¼. ÐÐ¸ÑÐ²",
          "purposeOfPayment": "ÐÐ¾Ð²ÐµÑÐ½ÐµÐ½Ð½Ñ ÐºÐ¾ÑÑÑÐ² Ð· Ð¿Ð¾ÑÐ¾ÑÐ½Ð¾Ð³Ð¾ ÑÐ°ÑÑÐ½ÐºÑ",
          "unzr": ""
        }
      ],
      "moneyElectionFundAccountsAllSum": 2477000,
      "candidateRegionaOrganiztionParty": false,
      "contributionOwnCandidateAllMoney": 1500000,
      "isCandidatePresidentialElections": false,
      "isCandidateDeputiesVillageCouncil": false,
      "paymentsFromElectionFoundAccounts": [
        {
          "sum": 1000,
          "rnokpp": "********",
          "rnokpp2": "**********",
          "userFIO": "ÐÐÐ¦ÐÐÐÐÐ ÐÐ Ð¢ÐÐÐÐ ÐÐ¡Ð¢ÐÐ \"ÐÐÐÐÐ Ð¦ÐÐÐÐÐ ÐÐÐÐ \"ÐÐÐÐÐ£Ð¡\"",
          "totalSum": 1000,
          "userFIO2": "Ð¢ÐµÑÐµÐ½ÐµÐ² Ð.Ð .",
          "accNumber": "686",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð®ÑÐ¸Ð´Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "3800",
          "typeOfWaste": "Ð¾Ð¿ÑÐ±Ð»ÑÐºÑÐ²Ð°Ð½Ð½Ñ ÑÐµÐºÐ²ÑÐ·Ð¸ÑÑÐ² ÑÐ°ÑÑÐ½ÐºÑ Ð² Ð·Ð°ÑÐ¾Ð±Ð°Ñ Ð¼Ð°ÑÐ¾Ð²Ð¾Ñ ÑÐ½ÑÐ¾ÑÐ¼Ð°ÑÑÑ",
          "articleCode2": "3430",
          "regionNumber": "413",
          "dateIncomeBack": "02.03.2023",
          "userLivingPlace": "Ð¼. ÐÐ¸ÑÐ²",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "typeOfIncomeBack": "ÐÐµÑÐµÑÐ°ÑÑÐ²Ð°Ð½Ð½Ñ Ð´Ð¾ Ð´ÐµÑÐ¶Ð°Ð²Ð½Ð¾Ð³Ð¾ Ð±ÑÐ´Ð¶ÐµÑÑ Ð²Ð½ÐµÑÐºÑÐ² Ð¾ÑÑÐ±, ÑÐºÑ Ð·Ð³ÑÐ´Ð½Ð¾ Ð· ÑÐ°ÑÑÐ¸Ð½Ð¾Ñ Ð²Ð¾ÑÑÐ¼Ð¾Ñ ÑÑÐ°ÑÑÑ 96  ÐÐ¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÐºÐ¾Ð´ÐµÐºÑÑ Ð£ÐºÑÐ°ÑÐ½Ð¸ Ð½Ðµ Ð¼Ð°ÑÑÑ Ð¿ÑÐ°Ð²Ð° Ð·Ð´ÑÐ¹ÑÐ½ÑÐ²Ð°ÑÐ¸ Ð²ÑÐ´Ð¿Ð¾Ð²ÑÐ´Ð½Ñ Ð²Ð½ÐµÑÐºÐ¸",
          "unzr": "",
          "unzr2": ""
        },
        {
          "sum": 22000,
          "rnokpp": "**********",
          "userFIO": "ÐÐ£Ð Ð ÐÐÐÐ ÐÐÐ¡ÐÐÐ¬ÐÐÐÐ§",
          "totalSum": 22000,
          "accNumber": "693",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "3900",
          "typeOfWaste": "Ð±Ð°Ð½ÐºÑÐ²ÑÑÐºÑ Ð¿Ð¾ÑÐ»ÑÐ³Ð¸, Ð½Ðµ Ð¿Ð¾Ð²ÑÐ·Ð°Ð½Ñ Ð· Ð²ÑÐ´ÐºÑÐ¸ÑÑÑÐ¼, ÑÑÐ½ÐºÑÑÐ¾Ð½ÑÐ²Ð°Ð½Ð½ÑÐ¼ Ñ Ð·Ð°ÐºÑÐ¸ÑÑÑÐ¼ ÑÐ°ÑÑÐ½ÐºÑ ÑÐ¾Ð½Ð´Ñ",
          "regionNumber": "413",
          "userLivingPlace": "Ð§ÐµÑÐºÐ°ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 58000,
          "rnokpp": "**********",
          "userFIO": "ÐÐÐÐÐÐ¡Ð¬ÐÐÐ ÐÐÐÐÐ ÐÐ¢ÐÐÐÐÐÐÐÐ§",
          "totalSum": 58000,
          "accNumber": "713",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "4100",
          "typeOfWaste": "Ð¿ÑÐ¸Ð´Ð±Ð°Ð½Ð½Ñ ÐºÐ°Ð½ÑÑÐ¾Ð²Ð°ÑÑÐ², Ð¿Ð°Ð¿ÐµÑÑ, ÑÐ½ÑÐ¸Ñ Ð¿ÑÐµÐ´Ð¼ÐµÑÑÐ² Ñ Ð¼Ð°ÑÐµÑÑÐ°Ð»ÑÐ² Ð´Ð»Ñ Ð¿ÑÐ¾Ð²ÐµÐ´ÐµÐ½Ð½Ñ Ð²Ð¸Ð±Ð¾ÑÑÐ¾Ñ ÐºÐ°Ð¼Ð¿Ð°Ð½ÑÑ",
          "regionNumber": "413",
          "userLivingPlace": "ÐÐ´ÐµÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 6000,
          "rnokpp": "**********",
          "userFIO": "Ð¡Ð»Ð¸Ð½ÑÐºÐ¾ ÐÐ¼Ð¸ÑÑÐ¾ ÐÐ¸ÑÐ°Ð¹Ð»Ð¾Ð²Ð¸Ñ",
          "totalSum": 6000,
          "accNumber": "748",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "4300",
          "typeOfWaste": "Ð¿Ð¾ÑÐ»ÑÐ³Ð¸, Ð¿Ð¾Ð²ÑÐ·Ð°Ð½Ñ Ð· Ð¿ÑÐ¾Ð²ÐµÐ´ÐµÐ½Ð½ÑÐ¼ Ð²Ð¸Ð±Ð¾ÑÑÐ¾Ñ ÐºÐ°Ð¼Ð¿Ð°Ð½ÑÑ (ÑÑÐ°Ð½ÑÐ¿Ð¾ÑÑÐ½Ñ, ÑÑÐ¸Ð´Ð¸ÑÐ½Ñ, Ð¾ÑÐµÐ½Ð´Ð° Ð¿ÑÐ¸Ð¼ÑÑÐµÐ½Ñ, Ð¾ÑÐ¾ÑÐ¾Ð½Ð° ÑÐ¾ÑÐ¾) ÑÐ° ÑÐ½ÑÑ Ð²Ð¸ÑÑÐ°ÑÐ¸",
          "regionNumber": "413",
          "userLivingPlace": "ÐÑÐ½Ð½Ð¸ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 50000,
          "rnokpp": "**********",
          "userFIO": "ÐÐ¾ÐºÑÑÐ¾Ð½Ð¾Ð²Ð° ÐÐ°ÑÐ°Ð»ÑÑ ÐÐ°Ð»ÐµÐ½ÑÐ¸Ð½ÑÐ²Ð½Ð°",
          "totalSum": 50000,
          "accNumber": "760",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "26.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "4400",
          "typeOfWaste": "Ð¿ÑÐ¸Ð´Ð±Ð°Ð½Ð½Ñ ÐºÐ°Ð½ÑÑÐ¾Ð²Ð°ÑÑÐ², Ð¿Ð°Ð¿ÐµÑÑ, ÑÐ½ÑÐ¸Ñ Ð¿ÑÐµÐ´Ð¼ÐµÑÑÐ² Ñ Ð¼Ð°ÑÐµÑÑÐ°Ð»ÑÐ² Ð´Ð»Ñ Ð¿ÑÐ¾Ð²ÐµÐ´ÐµÐ½Ð½Ñ Ð¿ÐµÑÐµÐ´Ð²Ð¸Ð±Ð¾ÑÐ½Ð¾Ñ Ð°Ð³ÑÑÐ°ÑÑÑ",
          "regionNumber": "413",
          "userLivingPlace": "Ð§ÐµÑÐºÐ°ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 29000,
          "rnokpp": "**********",
          "userFIO": "ÐÐ¾ÑÑÑÑÐ¹ÑÑÐº Ð¡ÐµÑÐ³ÑÐ¹ ÐÐ¾Ð»Ð¾Ð´Ð¸Ð¼Ð¸ÑÐ¾Ð²Ð¸Ñ",
          "totalSum": 29000,
          "accNumber": "747",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "5110",
          "typeOfWaste": "Ð²Ð¸Ð³Ð¾ÑÐ¾Ð²Ð»ÐµÐ½Ð½Ñ Ð´ÑÑÐºÐ¾Ð²Ð°Ð½Ð¸Ñ Ð¼Ð°ÑÐµÑÑÐ°Ð»ÑÐ² (Ð²Ð¸Ð±Ð¾ÑÑÐ¸Ñ Ð»Ð¸ÑÑÑÐ²Ð¾Ðº, Ð¿Ð¾ÑÑÐµÑÑÐ², Ð¿Ð»Ð°ÐºÐ°ÑÑÐ², Ð±ÑÐºÐ»ÐµÑÑÐ², Ð±ÑÐ¾ÑÑÑ ÑÐ° ÑÐ½ÑÐ¸Ñ Ð´ÑÑÐºÐ¾Ð²Ð°Ð½Ð¸Ñ Ð°Ð³ÑÑÐ°ÑÑÐ¹Ð½Ð¸Ñ Ð¼Ð°ÑÐµÑÑÐ°Ð»ÑÐ² ÑÐ¸ Ð´ÑÑÐºÐ¾Ð²Ð°Ð½Ð¸Ñ Ð²Ð¸Ð´Ð°Ð½Ñ, Ñ ÑÐºÐ¸Ñ ÑÐ¾Ð·Ð¼ÑÑÐµÐ½Ð¾ Ð¼Ð°ÑÐµÑÑÐ°Ð»Ð¸ Ð¿ÐµÑÐµÐ´Ð²Ð¸Ð±Ð¾ÑÐ½Ð¾Ñ Ð°Ð³ÑÑÐ°ÑÑÑ)",
          "regionNumber": "413",
          "userLivingPlace": "ÐÐ¸ÑÐ²ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 17000,
          "rnokpp": "**********",
          "userFIO": "ÐÐ°Ð»ÑÐ¹ÑÑÐº Ð®ÑÑÐ¹ ÐÐ»ÐµÐºÑÐ°Ð½Ð´ÑÐ¾Ð²Ð¸Ñ",
          "totalSum": 17000,
          "accNumber": "752",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "5120",
          "typeOfWaste": "Ð²Ð¸Ð³Ð¾ÑÐ¾Ð²Ð»ÐµÐ½Ð½Ñ ÑÐ¾Ð²Ð°ÑÑÐ² (Ð·Ð½Ð°ÑÐºÑÐ², ÐºÐ°Ð»ÐµÐ½Ð´Ð°ÑÑÐ², Ð¿Ð°ÐºÐµÑÑÐ², Ð¾Ð»ÑÐ²ÑÑÐ², ÑÑÑÐ¾Ðº ÑÐ¾ÑÐ¾) Ð²ÑÐ´Ð¿Ð¾Ð²ÑÐ´Ð½Ð¾ Ð´Ð¾ ÑÑÐ°ÑÑÑ 51 ÐÐ¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÐºÐ¾Ð´ÐµÐºÑÑ Ð£ÐºÑÐ°ÑÐ½Ð¸",
          "regionNumber": "413",
          "userLivingPlace": "ÐÑÐ²ÑÐ²ÑÑÐºÐ° Ð¾Ð±Ð»Ð°ÑÑÑ",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 19000,
          "rnokpp": "**********",
          "userFIO": "Ð¯ÑÐ¾ÑÐµÐ½ÐºÐ¾ ÐÐ»ÐµÐºÑÐ°Ð½Ð´Ñ Ð¡ÐµÑÐ³ÑÐ¹Ð¾Ð²Ð¸Ñ",
          "totalSum": 19000,
          "accNumber": "753",
          "typeOfAcc": "ÐÐ¾ÑÐ¾ÑÐ½Ð¸Ð¹",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "5130",
          "typeOfWaste": "Ð²Ð¸Ð³Ð¾ÑÐ¾Ð²Ð»ÐµÐ½Ð½Ñ Ð²ÑÐ´ÐµÐ¾Ð·Ð°Ð¿Ð¸ÑÑÐ²",
          "regionNumber": "413",
          "userLivingPlace": "Ð¼. ÐÐ¸ÑÐ²",
          "purposeOfPayment": "ÐÐ¿Ð»Ð°ÑÐ° Ð·Ð° Ð´Ð¾Ð³Ð¾Ð²Ð¾ÑÐ¾Ð¼",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 23000,
          "rnokpp": "**********",
          "userFIO": "Ð¤ÐÐÐÐ§ÐÐ ÐÐ¡ÐÐÐ Ð¨ÐÐÐÐ¬ÐÐÐÐ ÐÐÐÐ¢ÐÐ  ÐÐÐÐ ÐÐÐÐÐÐ§",
          "totalSum": 23000,
          "accNumber": "746",
          "typeOfAcc": "Поточний",
          "dateIncome": "27.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "5140",
          "typeOfWaste": "виготовлення аудіозаписів",
          "regionNumber": "413",
          "userLivingPlace": "Черкаська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 68000,
          "rnokpp": "**********",
          "userFIO": "ФІЗИЧНА ОСОБА-ПІДПРИЄМЕЦЬ КОЛІУШКО ІГОР БОРИСОВИЧ",
          "totalSum": 68000,
          "accNumber": "723",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "5150",
          "typeOfWaste": "виготовлення інформаційних наметів для передвиборної агітації",
          "regionNumber": "413",
          "userLivingPlace": "Одеська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 69000,
          "rnokpp": "**********",
          "userFIO": "ФІЗИЧНА ОСОБА-ПІДРИЄМЕЦЬ КВАШУК СЕРГІЙ ГРИГОРОВИЧ",
          "totalSum": 69000,
          "accNumber": "724",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "5210",
          "typeOfWaste": "оприлюднення агітаційних матеріалів на телебаченні",
          "regionNumber": "413",
          "userLivingPlace": "Вінницька область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 23000,
          "rnokpp": "**********",
          "userFIO": "ПРИВАТНИЙ НОТАРІУС КОПАЧ ЮЛІЯ ОЛЕГІВНА ",
          "totalSum": 23000,
          "accNumber": "731",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "5220",
          "typeOfWaste": "оприлюднення агітаційних матеріалів на радіо",
          "regionNumber": "413",
          "userLivingPlace": "Черкаська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 65000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ВИДАВНИЧИЙ ДІМ \"ОГО\"",
          "totalSum": 65000,
          "accNumber": "742",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5230",
          "typeOfWaste": "оприлюднення агітаційних матеріалів у друкованих засобах масової інформації",
          "regionNumber": "413",
          "userLivingPlace": "Київська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 72000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"РЕДАКЦІЯ ГАЗЕТИ \"ЧЕРНІГІВСЬКИЙ ВІСНИК\"",
          "totalSum": 72000,
          "accNumber": "755",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5300",
          "typeOfWaste": "розміщення матеріалів передвиборної агітації в мережі Інтернет",
          "regionNumber": "413",
          "userLivingPlace": "Львівська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 36000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ЮРИДИЧНА ФІРМА ПРАВНИК\"",
          "totalSum": 36000,
          "accNumber": "765",
          "typeOfAcc": "Поточний",
          "dateIncome": "28.02.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5410",
          "typeOfWaste": "транспортні послуги для реалізації заходів передвиборної агітації (перевезення листівок, плакатів та інших друкованих агітаційних матеріалів чи друкованих видань, у яких розміщено агітаційні матеріали, технічних засобів та обладнання для ведення передвиборної агітації тощо)",
          "regionNumber": "413",
          "userLivingPlace": "м. Київ",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 29000,
          "rnokpp": "********",
          "userFIO": "ПРИВАТНЕ ПІДПРИЄМСТВО \"АГЕНТСТВО ТЕРНОПІЛЬСЬКИХ НОВИН\"",
          "totalSum": 29000,
          "accNumber": "735",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5420",
          "typeOfWaste": "оренда будинків і приміщень всіх форм власності для проведення зборів громадян, зустрічей з виборцями, дебатів, дискусій, прес-конференцій та інших публічних заходів передвиборної агітації",
          "regionNumber": "413",
          "userLivingPlace": "Черкаська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 5000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ФІРЮЗА\"",
          "totalSum": 5000,
          "accNumber": "743",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5430",
          "typeOfWaste": "оренда обладнання та технічних засобів для ведення передвиборної агітації",
          "regionNumber": "413",
          "userLivingPlace": "Одеська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 60000,
          "rnokpp": "********",
          "userFIO": "АКЦІОНЕРНЕ ТОВАРИСТВО \"КОМЕРЦІЙНИЙ БАНК \"ГЛОБУС\"",
          "totalSum": 60000,
          "accNumber": "726",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5440",
          "typeOfWaste": "оренда інформаційних наметів для передвиборної агітації",
          "regionNumber": "413",
          "userLivingPlace": "Вінницька область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 94000,
          "rnokpp": "********",
          "userFIO": "ПРИВАТНЕ ПІДПРИЄМСТВО \"ВСЕУКРАЇНСЬКА ГАЗЕТА \"ВІСНИК+К\"",
          "totalSum": 94000,
          "accNumber": "728",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5450",
          "typeOfWaste": "оренда носіїв зовнішньої реклами (білбордів, вивісок, сітілайтів тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Черкаська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 26000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ІНТРЕЙД ТІМ\"",
          "totalSum": 26000,
          "accNumber": "762",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5460",
          "typeOfWaste": "розміщення друкованих агітаційних матеріалів на носіях зовнішньої реклами (білбордах, вивісках, сітілайтах тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Київська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 25000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ІНТРЕЙД ТІМ ДРІМ\"",
          "totalSum": 25000,
          "accNumber": "727",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5470",
          "typeOfWaste": "послуги електричного звязку (телефонного, телеграфного, документального, мереж та каналів передавання даних тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Львівська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 33000,
          "rnokpp": "********",
          "userFIO": "ПРИВАТНЕ ПІДПРИЄМСТВО \"МЕДІА РЕСУРС\"",
          "totalSum": 33000,
          "accNumber": "768",
          "typeOfAcc": "Поточний",
          "dateIncome": "01.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5480",
          "typeOfWaste": "послуги поштового звязку ",
          "regionNumber": "413",
          "userLivingPlace": "м. Київ",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 60000,
          "rnokpp": "********",
          "userFIO": "АКЦІОНЕРНЕ ТОВАРИСТВО \"КОМЕРЦІЙНИЙ БАНК \"ГЛОБУС\"",
          "totalSum": 100000,
          "accNumber": "1726",
          "typeOfAcc": "Поточний",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5440",
          "typeOfWaste": "оренда інформаційних наметів для передвиборної агітації",
          "regionNumber": "413",
          "userLivingPlace": "Вінницька область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 94000,
          "rnokpp": "********",
          "userFIO": "ПРИВАТНЕ ПІДПРИЄМСТВО \"ВСЕУКРАЇНСЬКА ГАЗЕТА \"ВІСНИК+К\"",
          "totalSum": 50000,
          "accNumber": "1728",
          "typeOfAcc": "Поточний",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5450",
          "typeOfWaste": "оренда носіїв зовнішньої реклами (білбордів, вивісок, сітілайтів тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Черкаська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 26000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ІНТРЕЙД ТІМ\"",
          "totalSum": 150000,
          "accNumber": "1762",
          "typeOfAcc": "Поточний",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5460",
          "typeOfWaste": "розміщення друкованих агітаційних матеріалів на носіях зовнішньої реклами (білбордах, вивісках, сітілайтах тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Київська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 25000,
          "rnokpp": "********",
          "userFIO": "ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ \"ІНТРЕЙД ТІМ ДРІМ\"",
          "totalSum": 50000,
          "accNumber": "1727",
          "typeOfAcc": "Поточний",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5470",
          "typeOfWaste": "послуги електричного звязку (телефонного, телеграфного, документального, мереж та каналів передавання даних тощо)",
          "regionNumber": "413",
          "userLivingPlace": "Львівська область",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        },
        {
          "sum": 33000,
          "rnokpp": "********",
          "userFIO": "ПРИВАТНЕ ПІДПРИЄМСТВО \"МЕДІА РЕСУРС\"",
          "totalSum": 150000,
          "accNumber": "1768",
          "typeOfAcc": "Поточний",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Юридична особа",
          "articleCode": "5480",
          "typeOfWaste": "послуги поштового звязку ",
          "regionNumber": "413",
          "userLivingPlace": "м. Київ",
          "purposeOfPayment": "Оплата за договором",
          "unzr": "",
          "rnokpp2": "",
          "unzr2": ""
        }
      ],
      "contributionVoluntaryFizUrAllMoney": 1000000,
      "isCandidateDeputiesDistrictCouncil": false,
      "isCandidateDeputiesRegionalCouncil": false,
      "isCandidateDeputiesTownshipCouncil": false,
      "receivingOnCurrentAccountsAllMoney": 147600,
      "isCandidateDeputiesPositionCityHead": false,
      "transferMoneyFromAccumulationAccount": [
        {
          "sum": 1000000,
          "rnokpp": "**********",
          "userFIO": "Гудкович Д.В.",
          "totalSum": 1000000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "22",
          "dateIncome": "22.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2500",
          "typeOfIncome": "Перерахування коштів з накопичувального рахунку",
          "userLivingPlace": "м. Київ",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Порошенко Р.Д.",
          "totalSum": 25000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "23",
          "dateIncome": "25.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "3700",
          "typeOfIncome": "Повернення помилкових надходжень коштів",
          "userLivingPlace": "Одеська область",
          "unzr": ""
        },
        {
          "sum": 25000,
          "rnokpp": "**********",
          "userFIO": "Турчинов С.П.",
          "totalSum": 25000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "24",
          "dateIncome": "25.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "3700",
          "typeOfIncome": "Повернення помилкових надходжень коштів",
          "userLivingPlace": "Дніпропетровська область",
          "unzr": ""
        },
        {
          "sum": 1500000,
          "rnokpp": "**********",
          "userFIO": "Гудкович Д.В.",
          "totalSum": 1500000,
          "accNumber": "UA033805260000002600800159741",
          "docNumber": "256",
          "dateIncome": "02.03.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2500",
          "typeOfIncome": "Перерахування коштів з накопичувального рахунку",
          "userLivingPlace": "м. Київ",
          "unzr": ""
        }
      ],
      "getMoneyFromVolunteerContributorFizUr": [
        {
          "sum": 25000,
          "rnokpp": "**********",
          "rnokpp2": "2154698732",
          "userFIO": "Теренев І.Р.",
          "userFIO2": "Теренев І.Р.",
          "dateIncome": "20.02.2023",
          "typeOfUser": "Фізична особа",
          "articleCode": "2300",
          "articleCode2": "3430",
          "dateIncomeBack": "02.03.2023",
          "typeOfIncomeBack": "Перерахування до державного бюджету внесків осіб, які згідно з частиною восьмою статті 96  Виборчого кодексу України не мають права здійснювати відповідні внески",
          "unzr": "",
          "userLivingPlace": ""
        }
      ],
      "isCandidateDeputiesDistrictCityCouncil": false,
      "isCandidateDeputiesPositionVillageHead": false,
      "managerOfMoneyElectionFoundLocationApt": "44",
      "refundsMoneyFromCurrentAccountAllMoney": 100000,
      "isCandidateDeputiesPositionTownshipHead": false,
      "managerOfMoneyElectionFoundLocationIndex": "03115",
      "managerOfMoneyElectionFoundIsLocationSame": "Так",
      "managerOfMoneyElectionFoundLocationKorpus": "А",
      "managerOfMoneyElectionFoundLocationRegion": "м.Київ",
      "managerOfMoneyElectionFoundLocationStreet": "ву». ÐÐ²ÑÐ¾ÑÐµÐ¼Ð¾Ð½ÑÐ½Ð°",
      "paymentsFromElectionFoundAccountsAllMoney": 23000,
      "refundsMoneyFromVolunteerContributorFizUr": [
        {
          "sum": 25000,
          "rnokpp": "**********",
          "rnokpp2": "2154698732",
          "userFIO": "Ð¢ÐµÑÐµÐ½ÐµÐ² Ð.Ð .",
          "userFIO2": "Ð¢ÐµÑÐµÐ½ÐµÐ² Ð.Ð .",
          "dateIncome": "20.02.2023",
          "typeOfUser": "Ð¤ÑÐ·Ð¸ÑÐ½Ð° Ð¾ÑÐ¾Ð±Ð°",
          "articleCode": "2300",
          "articleCode2": "3430",
          "dateIncomeBack": "02.03.2023",
          "sumOfIncomeBack": 25000,
          "typeOfIncomeBack": "ÐÐµÑÐµÑÐ°ÑÑÐ²Ð°Ð½Ð½Ñ Ð´Ð¾ Ð´ÐµÑÐ¶Ð°Ð²Ð½Ð¾Ð³Ð¾ Ð±ÑÐ´Ð¶ÐµÑÑ Ð²Ð½ÐµÑÐºÑÐ² Ð¾ÑÑÐ±, ÑÐºÑ Ð·Ð³ÑÐ´Ð½Ð¾ Ð· ÑÐ°ÑÑÐ¸Ð½Ð¾Ñ Ð²Ð¾ÑÑÐ¼Ð¾Ñ ÑÑÐ°ÑÑÑ 96  ÐÐ¸Ð±Ð¾ÑÑÐ¾Ð³Ð¾ ÐºÐ¾Ð´ÐµÐºÑÑ Ð£ÐºÑÐ°ÑÐ½Ð¸ Ð½Ðµ Ð¼Ð°ÑÑÑ Ð¿ÑÐ°Ð²Ð° Ð·Ð´ÑÐ¹ÑÐ½ÑÐ²Ð°ÑÐ¸ Ð²ÑÐ´Ð¿Ð¾Ð²ÑÐ´Ð½Ñ Ð²Ð½ÐµÑÐºÐ¸",
          "sumOfIncomeBackGOV": 25000,
          "unzr": "",
          "userLivingPlace": ""
        }
      ],
      "managerOfMoneyElectionFoundLocationCountry": "Ð£ÐºÑÐ°ÑÐ½Ð°",
      "managerOfMoneyElectionFoundLocationBuilding": "15",
      "managerOfMoneyElectionFoundLocationDistrict": " Ð¡Ð²ÑÑÐ¾ÑÐ¸Ð½ÑÑÐºÐ¸Ð¹",
      "managerOfMoneyElectionFoundIndividualNameIpn": "2930021775",
      "transferMoneyFromAccumulationAccountAllMoney": 50000,
      "isIdCardmanagerOfMoneyElectionFoundIndividual": false,
      "managerOfMoneyElectionFoundIndividualLastName": "ÐÑÐ´ÐºÐ¾Ð²Ð¸Ñ",
      "managerOfMoneyElectionFoundLocationCountryFact": "Ð£ÐºÑÐ°ÑÐ½Ð°",
      "isPassportmanagerOfMoneyElectionFoundIndividual": false,
      "refundsMoneyFromVolunteerContributorFizUrAllMoney": 0,
      "managerOfMoneyElectionFoundIndividualNameMiddleName": "ÐÑÑÐ°Ð»ÑÐ¹Ð¾Ð²Ð¸Ñ",
      "managerOfMoneyElectionFoundIndividualtNameFirstName": "ÐÐ¼Ð¸ÑÑÐ¾",
      "managerOfMoneyElectionFoundIndividualNameDateOfBirthDay": "21.03.1980",
      "id": "19ca02a0-bc23-11ed-baae-193321b3e9d5"
    }
  };


export async function get_records() {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching =  true;

    const result = await fetch(url, Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
    
}

export async function get_reestr_data(id) {

    //return test_data_report;

    paramApi.method = "GET";

   // window.get_crypto('https://eu.iit.com.ua/sctest/user.php', 'JSON')

    //.then(i=>console.log('res_crypto', i) )
    //.catch(e=>console.log('res_crypto err', e));

    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(url_record.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
}

export async function get_report_as_attach(id) {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(url_attach.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.blob();  

    isFetching =  false;

    return {data: data, name: result.headers.get('content-disposition')};

}