import React from 'react';


export function ContentAbout(props){
    return (<div style={{ padding: "50px 100px"}}>
        <h2>ПРО РЕЄСТР</h2>
<p><b>Реєстр POLITDATA</b> - це портал, розроблений Національним агентством з питань запобігання корупції, на якому містяться фінансові дані політичних партій, що функціонують в Україні.
Цей реєстр створений, щоб спростити процес подання звітності для політичних партій, а також забезпечити кращий доступ до цієї інформації для громадян.
Його розробка передбачена Законом України “Про політичні партії”.</p>


<h3>Переваги Реєстру для політичних партій</h3>

<p><b>Простий доступ та користування.</b> Кваліфікований електронний підпис (КЕП) для керівника та підтвердження його статусу від НАЗК - ось і все, що має отримати партія, аби почати подавати звітність до Реєстру. Заповнення звіту у Реєстрі розподілено на 10 чітких та зрозумілих кроків. А для тих, хто користується порталом уперше, НАЗК розробило короткі інструкції з користування.
Не потрібно витрачати кошти на друк та поштові витрати. До появи Реєстру партії подавали звіти до НАЗК у паперовій формі та на електронному носії (наприклад, флешці або CD-диску). При цьому, роздруковані звіти найбільших партій лише за один квартал займали цілі коробки та важили десятки кілограмів. Аби надіслати такий звіт поштою, партія мала витратити додаткові кошти та час своїх співробітників.
Усі персональні дані внескодавців знеособлюються у публічній частині реєстру автоматично. Вся особиста інформація про осіб, які фінансують діяльність політичних партій, прихована.
</p>

<h3>Переваги реєстру для громадян</h3>

<p><b>Звіти партій - як на долоні.</b> Раніше НАЗК могло публікувати лише відскановані звіти політичних партій. Щоб знайти необхідну інформацію у цих документах, необхідно було витрати багато зусиль та часу. Тепер завдяки Реєстру кожну необхідну цифру у звіті партії можна відшукати легко і швидко.
Відкриті дані. Наразі доступні базові дані про функціонування Реєстру. Ми продовжуємо працювати над модулем, який дозволить громадянам краще зрозуміти, за які кошти партії організовують свою роботу.
Прозоріші партії - ефективніші політичні процеси. Відкриті дані дозволять відслідковувати, хто фінансує українські політичні партії та аналізувати, які приватні інтереси можуть мати їхні внескодавці. Це дозволить громадянам бути більш поінформованими під час парламентських та місцевих виборів.
</p>

</div>)   
}