import React, { } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';


import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding:'10px'
      },
    elements: {
        marginTop: '10px'
    }
   } )
   );

export function ReportMainInfo(props) {

    const classes = useStyles();

    if(!props.show) return <div/>;

    const head = [
        props.data.headFirstName,
        props.data.headLastName,
            [
                props.data.headLocationCountry,
                props.data.headLocationRegion
            ].join(', '),

        ].join(' ');

        const juraddr = [
            props.data.partyLocationCountry || "Україна",
            props.data.partyLocationIndex,
            props.data.partyLocationRegion,
            props.data.partyLocationDistrict,
            props.data.partyLocationCity,
            props.data.partyLocationStreet,
            props.data.partyLocationBuilding + (props.data.partyLocationKorpus ? " корп. " + props.data.partyLocationKorpus: ""),
            props.data.partyLocationApt
        ].join(', ').replace(', ,', '').replace(/^\,/g, '');

        const factaddr = props.data.partyLocationRegionFact ? [
            props.data.partyLocationCountryFact,
            props.data.partyLocationIndexFact,
            props.data.partyLocationRegionFact,
            props.data.partyLocationDistrictFact,
            props.data.partyLocationCityFact,
            props.data.partyLocationStreetFact,
            props.data.partyLocationBuildingFact + (props.data.partyLocationKorpusFact ? " корп. " + props.data.partyLocationKorpusFact: ""),
            props.data.partyLocationAptFact
        ].join(', ').replace(', ,', '').trim().replace(/^\,/g, '')
        :"Збігається з адресою місцезнаходження";


    return (

        <Paper >
        <div className={classes.root}>
        
            <Grid container spacing={3}>
            
            
            <Grid item xs={4}>

                <TextField
                  id="type"
                  name="type"
                  label="Тип звіту"
                  fullWidth
                  value={props.data.type || props.data.types}
                  InputLabelProps={{ shrink: true }}
                />

            </Grid>

            <Grid item xs={6}>
            <TextField
                  id="ipn"
                  name="ipn"
                  label="Ідентифікаційний код юридичної особи"
                  fullWidth
                  value={props.data.partyCode}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={12}>

            <TextField
                  id="head"
                  name="head"
                  label="Керівник"
                  fullWidth
                  value={head}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />
            
            </Grid>
            
            

            

            <Grid item xs={12}>
            <TextField
                  id="addr"
                  name="addr"
                  label="Місцезнаходження"
                  fullWidth
                  value={juraddr}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />
            </Grid>

            

            <Grid item xs={12}>
            <TextField
                  id="factaddr"
                  name="factaddr"
                  label="Фактичне місцезнаходження"
                  fullWidth
                  value={factaddr}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />
            </Grid>

            <Grid item xs={12}>
            <TextField
                  id="factaddr"
                  name="factaddr"
                  label="Офіційний веб-сайт"
                  fullWidth
                  value={props.data.website || 'Відсутній'}
                  InputLabelProps={{ shrink: true }}
                  multiline
                />
            </Grid>


            <Grid item xs={4}>
            <TextField
                  id="pers1"
                  name="pers1"
                  helperText="Кількість працюючих на умовах трудового договору"
                  fullWidth
                  value={props.data.quantityFirst || "0"}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={4}>
            <TextField
                  id="pers2"
                  name="pers2"
                  helperText="Кількість працюючих на умовах цивільно-правового договору"
                  fullWidth
                  value={props.data.quantitySecond || "0"}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>

           
            </Grid>
        </div> 
        </Paper>

        
        )
}