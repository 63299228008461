
const params = require('./../../../conf/api.json');

const paramApi = params.paramApi;

let isFetching = false;

const url="/api/getallreportsshort";

const url_record="/api/getreportzip/:id";

const url_attach="/api/getreportattach/:id";

const url_attach_election="/api/getreportattach_election/:id";



export async function get_records() {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching =  true;

    const result = await fetch(url, Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
    
}

export async function get_reestr_data(id) {
    paramApi.method = "GET";

   // window.get_crypto('https://eu.iit.com.ua/sctest/user.php', 'JSON')

    //.then(i=>console.log('res_crypto', i) )
    //.catch(e=>console.log('res_crypto err', e));

    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(url_record.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
}

export async function get_report_as_attach(id, type="/reportview") {

    console.log('get_report_as_attach type', type);

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching = true;

    const url_report = type == "/reportview" ? url_attach : url_attach_election;

    const result = await fetch(url_report.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.blob();  

    isFetching =  false;

    return {data: data, name: result.headers.get('content-disposition')};

}