import React, { Component } from 'react';
import { Table } from '@material-ui/core';
import withStyles from "@material-ui/styles/withStyles";
import MaterialTable from 'material-table';

import '../table_party.css';

import Container from '@material-ui/core/Container';




import { party_table } from './party_table';
import ButtomBar from './ButtomBar';


const params = require('./../conf/api.json');


const urlApiParse = params.main_api.url + '/getpartylistmain';

const urlApiParseRegions = params.main_api.url + '/getpartylistregion';

const paramApi = params.paramApi;

paramApi.method = "GET";

const styles = theme => ({
    table: {
      minWidth: 1000,
      marginTop: 10
    },
    head:{
        fontWeight:700
    }
  });

const party_headers = [
    {title: 'Повне найменування політичної партії', field: 'name'},
    {title: 'Ідентифікаційний код', field: 'edrpou' },
    {title: 'Центральний чи регіональний', field: 'type'},
    //{title: 'Адреса місцезнаходження керівних органів (юридична адреса)', field: 'jur_addr'},
    //{title: 'Адреса місцезнаходження керівних органів (фактична адреса)', field: 'fact_addr'}
];

const style_region = { marginLeft: '15px'};

const style_title = {
    fontSize: 28,
    lineHeight: "32px",
    fontWeight: 700,
    paddingLeft: 12,
    borderLeft: "solid 5px  rgba(62, 109, 165, 1)",
    //marginLeft: -16,
  };
  

class PartInfo extends Component {

    constructor(props){
        super(props);

        this.state = {
            party_info: [],
            isFetching_main: false,
            isFetching_region: false,
            count: {
                centr: 0, region: 0
            }
        }

    }

    componentWillMount(){

        this.getInfo();

    }

    setDataInfo = (data, regions) =>{

        const arr = data.map(el=>{

            return {name: el.name, type: 'центральний', jur_addr: el.location, fact_addr: el.locationFact, id: el.unitId, edrpou: el.code};
        });

        regions.forEach(el=>{
            arr.push({ name: <li style={style_region}>{el.name}</li>, type: 'регіональний', jur_addr: el.location, fact_addr: el.locationFact, id: el.unitId, edrpou: el.code, parentId: el.politPartyUnitId })
        })

        this.setState({ party_info: arr, count: { centr: data.length, region: regions.length} });
    }

    getInfo = async () => {
        const main = await this.getInfoMainParty();
        const regions = await this.getInfoRegionParty();


        this.setDataInfo(main.data, regions.data);
    }

    getInfoRegionParty = async ()=> {
        if( this.state.isFetching_region ) return;
        this.setState( {isFetching_region: true});
        const result = await fetch(urlApiParseRegions, Object.assign({}, paramApi) );
        const data = await result.json();
        this.setState( { isFetching_region: false} );
    
        return data;
    }


    getInfoMainParty = async ()=> {
        if( this.state.isFetching_main ) return;
        this.setState( {isFetching_main: true});
        const result = await fetch(urlApiParse, Object.assign({}, paramApi) );
        const data = await result.json();
        this.setState( { isFetching_main: false} );

        return data; 
    }
    

    render(){ 

        const { classes } = this.props;


        return(
        
        <React.Fragment>
            
        <Container>
            
        <MaterialTable
            title={<div style={style_title}> Зареєстровані політичні партії в реєстрі</div>}
            columns={party_headers}
            data={this.state.party_info}
            parentChildData={(row,rows)=> {  return !row.parentId ? null : rows.find(a=>a.id === row.parentId) } }
            options={
                {
                    search: true, 
                    filtering: true,
                    pageSize: 10,
                    pageSizeOptions: [10,20,50]
                }
            }
            localization={
                {
                    toolbar:{
                        searchTooltip: 'Пошук',
                        searchPlaceholder: 'Знайти' 
                    },
                    body:{
                        filterRow: {filterTooltip: 'Фільтр'}
                    },
                    pagination:{
                        labelDisplayedRows: '{from}-{to} зі {count}',
                        labelRowsPerPage: 'записів на сторінку:',
                        labelRowsSelect: 'записів на сторінку'
                    }
                }
            }
        />
        </Container>
        <ButtomBar />
        </React.Fragment>
    )
    }


}

export default withStyles(styles)(PartInfo);