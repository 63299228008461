import React from 'react';

import {createTheme, ThemeProvider, Card, CardContent, Typography, Box, LinearProgress} from '@mui/material';

const themeObj = {
    palette: {
        text: {
          primary: "#000000",
          secondary: "#46505A",
          positive: "#41C07C",
        },
        primary: {
          main: "rgba(255, 215, 47, 1)",
          darker: "#fff",
        },
        neutral: {
          main: "#64748B",
          contrastText: "#fff",
        },
    },
    components:{
      MuiLinearProgress:{
        styleOverrides:{
          root: {
            height: '16px'
          }
        }
      },
      MuiCard: {
        variants: [
          {
            props: {
              variant: "grey-card",
            },
            style: {
              borderRadius: "25px",
              padding: "35px",
              backgroundColor: "#F8F8F8",
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "subtitle",
            },
            style: {
              fontSize: 20,
              lineHeight: "18px",
              paddingRight: 12,
              fontWeight: 700,
              whiteSpace: "nowrap",
              minWidth: 120,
            },
          },
          {
            props: {
              variant: "status-text",
            },
            style: {
              fontSize: 16,
              lineHeight: "18px",
              fontWeight: 700,
              color: "#fff",
            },
          },
          {
            props: {
              variant: "status-desciption",
            },
            style: {
              fontSize: 20,
              lineHeight: "22px",
              fontWeight: 300,
              textAlign: "center",
            },
          },
          {
            props: {
              variant: "subtitle-thin",
            },
            style: {
              fontSize: 17,
              lineHeight: "18px",
              paddingRight: 12,
              paddingLeft: 12,
              fontWeight: 300,
              whiteSpace: "nowrap",
            },
          },
        ],
      }
      
    }
  };

const kv_labes = {
    1: "I",
    2: "II",
    3: "III",
    4: "IV",
    5: "Рік"
};

export function Progress(props){

    const { accepts, finished, kv, progress } = props;

    const value = accepts && Math.trunc(finished/accepts * 100);

    themeObj.palette.primary.main = accepts == 0 ? "rgb(242, 245, 243)" : accepts == finished ? "rgb(11, 206, 78)" : finished == 0 ? "rgb(236, 61, 61)" : "rgba(255, 215, 47, 1)";

    const theme = createTheme(themeObj);

    return(
        <ThemeProvider theme={theme}>
        <Card sx={{ marginBottom: 1 }}>
            <CardContent sx={{ display: "flex" }}>
            <Typography component='span' variant='subtitle'>
                {kv_labes[kv]} {(kv < 5) && 'квартал' || ''}
            </Typography>
            <Box sx={{ width: `80%` }}>
            <LinearProgress
                variant='buffer'
                value={value * progress}
                valueBuffer={100}
                />
            </Box>
            <Box><span style={{ 'margin-left': '5px' }}>{value * progress}%</span></Box>
            <Typography component='span' variant='subtitle-thin'>
                {finished} з {accepts} звітів
            </Typography>
            </CardContent>
      </Card>
      </ThemeProvider>
    )
}