const params = require('./../../../conf/api.json');

const paramApi = params.paramApi;

let isFetching = false;


const url_short_elections = "/api/regional_elect_reposrt_short";


export async function get_election_record_short(type){

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching =  true;

    const result = await fetch(`${url_short_elections}?type=${type}`, Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
    

}