import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";


import {
  Box,
  ThemeProvider,
  createTheme,
  Typography,
  Link
} from "@mui/material";

import "./styles.css";

import electionBgImage from "./../../assets/img/bg_election.jpg";
import arrowWhite from "./../../assets/img/arrow_white.svg";
import arrowBlack from "./../../assets/img/arrow_black.svg";

import { get_info_stat } from './actions';

import { StatisticBlock } from './components/partfinance/statistics';


const theme = createTheme({
  palette: {
    text: {
      primary: "#000000",
      secondary: "#46505A",
      positive: "#41C07C",
    },
    primary: {
      main: "rgba(255, 215, 47, 1)",
      darker: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  components: {
    MuiPaper: {
      variants: [
        {
          props: {
            variant: "section-wrapper",
          },
          style: {
            padding: "50px 65px 20px",
            marginTop: "30px",
            borderRadius: "11px",
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: {
            variant: "grey-card",
          },
          style: {
            borderRadius: "25px",
            padding: "35px",
            backgroundColor: "#F8F8F8",
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: {
            variant: "rel",
          },
          style: {
            position: "relative",
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: "outlined",
          },
          style: {
            width: "320px",
            marginTop: "15px",
            borderRadius: "11px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#3c52b2",
            },
          },
        },
        {
          props: {
            variant: "contained",
          },
          style: {
            width: "320px",
            marginTop: "15px",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#3c52b2",
            },
          },
        },
      ],
    },
    MuiLinearProgress: {
      
      styleOverrides:{
          root: {
            height: '16px'
          }
        
      },
      variants: [
        {
          props: {
            variant: "determinate",
          },
          style: {
            height: "20px",
          },
        },

        {
          props: {
            variant: "determinate2",
          },
          style: {
            height: "20px",
            color: "red",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "body",
          },
          style: {
            fontSize: 20,
            lineHeight: "22px",
            margin: "16px 0",
            paddingRight: "60px",
          },
        },
        {
          props: {
            variant: "body-thin",
          },
          style: {
            fontSize: 20,
            lineHeight: "22px",
            margin: "16px 0 32px",
            paddingRight: "10px",
            fontWeight: 300,
          },
        },
        {
          props: {
            variant: "body-white",
          },
          style: {
            fontSize: 20,
            lineHeight: "22px",
            margin: "16px 0",
            paddingRight: "60px",
            color: "#fff",
            fontWeight: 300,
          },
        },
        {
          props: {
            variant: "h3",
          },
          style: {
            fontSize: 28,
            lineHeight: "32px",
            fontWeight: 700,
            paddingLeft: 12,
            borderLeft: "solid 5px  rgba(62, 109, 165, 1)",
            marginLeft: -16,
          },
        },

        {
          props: {
            variant: "h3-inside",
          },
          style: {
            fontSize: 28,
            lineHeight: "32px",
            fontWeight: 700,
            paddingLeft: 12,
            marginBottom: 12,
            borderLeft: "solid 5px  rgba(62, 109, 165, 1)",
          },
        },
        {
          props: {
            variant: "h4",
          },
          style: {
            fontSize: 24,
            lineHeight: "28px",
            fontWeight: 700,
            paddingLeft: 12,
            borderLeft: "solid 5px rgba(255, 215, 47, 1)",
          },
        },
        {
          props: {
            variant: "h3-white",
          },
          style: {
            fontSize: 28,
            lineHeight: "32px",
            fontWeight: 700,
            paddingLeft: 12,
            borderLeft: "solid 5px #fff",
            color: "#fff",
          },
        },
        {
          props: {
            variant: "status-text",
          },
          style: {
            fontSize: 16,
            lineHeight: "18px",
            fontWeight: 700,
            color: "#fff",
          },
        },
        {
          props: {
            variant: "status-desciption",
          },
          style: {
            fontSize: 20,
            lineHeight: "22px",
            fontWeight: 300,
            textAlign: "center",
          },
        },
        {
          props: {
            variant: "note",
          },
          style: {
            fontSize: 20,
            lineHeight: "23px",
            fontWeight: 300,
            color: "#FF3939",
            margin: "60px 0 0 auto",
            width: "60%",
          },
        }
      ],
    },
  },
});


const today = new Date();

//const curYear = today.getFullYear();

const curYear = 2022;



const Content = () => {
  const [progress, setProgress] = React.useState(10);

  const [data_stat, setDataStat] = React.useState(null);

  const initData = async () => { setDataStat(await get_info_stat(curYear)) };

  React.useEffect(()=>{
    if(data_stat == null) initData();
  },[]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
      1  
      //prevProgress >= 1 ? 0 : prevProgress + 0.1
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Container component='main' maxWidth='100%'>
      <ThemeProvider theme={theme}>
        <Container maxWidth='xl' component='section'>
          <Paper
            elevation={0}
            variant='section-wrapper'
            className='partyFinances'
            sx={{ backgroundColor: "rgba(248, 248, 248, 1)" }}>
            <Box sx={{ marginBottom: "50px" }}>
              <Typography component='h1' variant='h3'>
                Партійні фінанси
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7} lg={8} variant='rel'>
                  <Typography component='p' variant='body'>
                    З метою забезпечення прозорості своєї діяльності політичні
                    партії щоквартально, не пізніше ніж на сороковий день після
                    закінчення звітного кварталу, зобов’язана подати до
                    Національного агентства з питань запобігання корупції звіт
                    про майно, доходи, витрати і зобов’язання фінансового
                    характеру (у тому числі її місцевих організацій, які у
                    встановленому порядку набули статусу юридичної особи).
                  </Typography>
                  <img src={arrowBlack} alt='' className='arrow'></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                <NavLink to="/partlist" >
                <Button variant='contained' size='large'>
                    Партії в Реєстрі
                  </Button>
                </NavLink>
                  
                  <br />
                  <NavLink to="/reports" >
                  <Button variant='contained' size='large'>
                    Квартальна звітність
                  </Button>
                  </NavLink>
                </Grid>
              </Grid>
            </Box>

            <StatisticBlock />

            {false && <Box sx={{ marginBottom: "50px" }}>
              <Typography component='h2' variant='h4'>
                ПРОГРЕС перевірки квартальних фінансових звітів
              </Typography>
              <Card sx={{ display: "flex", margin: "30px 0" }}>
                <Box className='statusInfo executed' sx={{ width: "30%" }}>
                  <Typography component='span' variant='status-text'>
                    700 | 30%
                  </Typography>
                </Box>
                <Box className='statusInfo onHold' sx={{ width: "15%" }}>
                  <Typography component='span' variant='status-text'>
                    350 | 15%
                  </Typography>
                </Box>
                <Box className='statusInfo notStarted' sx={{ width: "55%" }}>
                  <Typography component='span' variant='status-text'>
                    2000 | 55%
                  </Typography>
                </Box>
              </Card>
              <Box sx={{ display: "flex" }}>
                <Typography
                  component='div'
                  variant='status-desciption'
                  sx={{ width: "30%" }}>
                  Виконано перевірку
                </Typography>
                <Typography
                  component='div'
                  variant='status-desciption'
                  sx={{ width: "15%" }}>
                  Призупинено перевірку
                </Typography>
                <Typography
                  component='div'
                  variant='status-desciption'
                  sx={{ width: "55%" }}>
                  Не розпочато
                </Typography>
              </Box>
              <Typography component='div' variant='note'>
                *У зв’язку з військовою агресією російської федерації проти
                України Законом продовжено терміни подання звітів політичними
                партіями
              </Typography>
            </Box> }
          </Paper>
        </Container>

        <Paper
          component='section'
          elevation={0}
          variant='section-wrapper'
          className='election'
          style={{
            backgroundImage: `url(${electionBgImage})`,
            backgroundSize: "cover",
          }}>
          <Container maxWidth='xl'>
            <Box
              sx={{
                marginBottom: "50px",
                paddingBottom: "36px",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.46)",
              }}>
              <Typography component='h2' variant='h3-white'>
                Вибори
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={7} lg={8} variant='rel'>
                  <Typography component='p' variant='body-white'>
                    Вибори – передбачена конституціями та законами переважної
                    більшості держав форма прямого народовладдя, яка полягає в
                    тому, що шляхом голосування формуються представницькі органи
                    державної влади та місцевого управління (самоврядування).
                  </Typography>
                  <img src={arrowWhite} alt='' className='arrow'></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <NavLink to="/election_pr">
                  <Button variant='outlined' size='large'>
                    Президентські
                  </Button>
                  </NavLink>
                  <NavLink to="/election_prl">
                  <Button variant='outlined' size='large'>
                    Парламентські
                  </Button>
                  </NavLink>
                  <NavLink to="/election">
                    <Button variant='outlined' size='large'>
                      Місцеві
                    </Button>
                  </NavLink>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginBottom: "50px",
                paddingBottom: "36px",
              }}>
              <Typography component='h2' variant='h3-white'>
                Референдум
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={7} lg={8} variant='rel'>
                  <Typography component='p' variant='body-white'>
                    Референдум – найважливіший інститут прямої демократії. Це
                    один із способів участі громадськості у прийнятті рішень,
                    важливих для держави та для кожного окремого громадянина.
                  </Typography>
                  <img src={arrowWhite} alt='' className='arrow'></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <Button variant='outlined' size='large'>
                    Всеукраїнський
                  </Button>
                  <Button
                    variant='outlined'
                    size='large'
                    sx={{ marginTop: "15px", width: "320px" }}>
                    Місцевий
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Card
              sx={{
                padding: "30px 25px 20px 50px",
                marginBottom: "50px",
                borderRadius: "15px",
              }}>
              <Typography component='h1' variant='h3'>
                Про реєстр
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={7} lg={8} variant='rel'>
                  <Typography component='p' variant='body'>
                    <strong>Реєстр POLITDATA</strong> - це портал, розроблений
                    Національним агентством з питань запобігання корупції, на
                    якому містяться фінансові дані політичних партій, що
                    функціонують в Україні.
                  </Typography>
                  <img src={arrowBlack} alt='' className='arrow'></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <NavLink to='/about'>
                  <Button variant='contained' size='large'>
                    Більше про реєстр
                  </Button>
                  </NavLink>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Paper>
        <Container
          maxWidth='xl'
          component='section'
          sx={{
            marginTop: "30px",
            marginBottom: "50px",
          }}>
          <Grid container spacing='20'>
            <Grid item sm={12} md={4}>
              <Card variant='grey-card'>
                <Typography component='h4' variant='h3-inside'>
                  АРІ
                </Typography>
                <Box display='flex'>
                  <Typography component='p' variant='body-thin'>
                    Для детального аналізу можна застосувати простий відкритий
                  </Typography>
                  <a href='/api/swagger' target="_blank">
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ width: "80px", float: "right", height: "42px" }}>
                    API
                  </Button>
                  </a>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={12} md={8}>
              <Card variant='grey-card'>
                <Typography component='h4' variant='h3-inside'>
                  Аналітика
                </Typography>
                <Box display='flex'>
                  <Typography component='p' variant='body-thin'>
                    Простий та зрозумілий інструмент моніторингу за політичними
                    фінансами, надходженням і використанням коштів виборчих
                    фондів та фондів референдумів
                  </Typography>
                  <NavLink to='/statboard'>
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ height: "42px", minWidth: "250px" }}>
                    Ознайомитися
                  </Button>
                  </NavLink>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card variant='grey-card'>
                <Typography component='h4' variant='h3-inside'>
                  Як подати квартальний звіт?
                </Typography>
                <Box display='flex'>
                  <Typography component='p' variant='body-thin'>
                    Детальні інструкції для роботи з реєстром та зручного
                    заповнення квартальних фінансових звітів політичних партій.
                    <br></br>
                    <br></br>
                    <strong>Є питання? </strong>
                    <br></br>
                    Звертайтесь за телефоном 044 200 06 94 або е-поштою
                    <Link href='mailto:support@nazk.gov.ua'>
                      support@nazk.gov.ua
                    </Link>
                  </Typography>
                  <Box sx={{ width: "250px" }}>
                    <NavLink to="/video">
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Відео
                    </Button>
                    </NavLink>
                    <Link href='https://nazk.gov.ua/wp-content/uploads/2021/01/politdata.zip'>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Інструкції
                    </Button>
                    </Link>
                    <Link href='https://wiki.nazk.gov.ua/category/zapobigannya-politychnij-koruptsiyi/'>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Роз’яснення
                    </Button>
                    </Link>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card variant='grey-card'>
                <Typography component='h4' variant='h3-inside'>
                  Як подати звіт виборчого фонду?
                </Typography>
                <Box display='flex'>
                  <Typography component='p' variant='body-thin'>
                    Детальні інструкції для роботи з реєстром та зручного
                    заповнення звітів виборчих фондів кандидатів, політичних
                    партій та їх місцевих організацій.
                    <br></br>
                    <br></br>
                    <strong>Є питання? </strong>
                    <br></br>
                    Звертайтесь за телефоном 044 200 06 94 або е-поштою
                    <Link href='mailto:support@nazk.gov.ua'>
                      support@nazk.gov.ua
                    </Link>
                  </Typography>
                  <Box sx={{ width: "250px" }}>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Відео
                    </Button>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Інструкції
                    </Button>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ height: "42px", width: "250px" }}>
                      Роз’яснення
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Container>
  );
};

export default Content;
