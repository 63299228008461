import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Link as RouterLink } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';


import { Grid, Button } from '@material-ui/core';

import { yellow } from '@material-ui/core/colors';

import { Facebook, YouTube, Twitter, Telegram } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '1080px',
    background: '#1C4860',
    color: '#FFFFFF',
    lineHeight: '1.7'
  },
  part3: {
      marginTop: '30px',
      padding: '20px',
      paddingLeft: '30px',
      borderLeft: 'solid 1px #325b70',
  },
  part1: {
      marginTop: '30px',
      padding: '20px',
  },
  part2: {
      marginTop: '30px',
      padding: '20px',
  },
  inner:{
    padding: '0px 50px 0px 50px',
    maxWidth: '1280px',
    margin: 'auto'
  },
  button:{
    margin: '20px'
  },
  ico_soc: {
    color: '#FFFFFF',
    paddingRight: '20px',
    '& :hover':{
      color: 'gray',
    }
  },
  site_link:{
    marginLeft: '40px'
  },
  url_link: {
    color: '#FFFFFF',
    fontWeight: '400',
    '&:hover': {
      color: '#FCDA4B',
    }
  },
  external_link: {
    color: 'inherit',
    textDecoration: 'none',
    fontWeight: '400',
    '&:hover': {
      color: '#FCDA4B',
    },
  }


}));

const menu_1 = [
    {text: "Партії в Реєстрі", url: "/partlist"},
    {text: "Квартальна звітність партій", url: "/reports"},
    {text: "АРІ", url: "https://politdata.nazk.gov.ua/api/swagger/"},
//    {text: "Всеукраїнський референдум", url: ""},
//    {text: "Місцевий референдум", url: ""},
//     {text: "Як подати квартальний звіт?", url:""}
];

const menu_2 = [
    {text: "Президентські вибори", url: "/election_pr"},
    {text: "Парламентські вибори", url: "/election_prl"},
    {text: "Місцеві вибори", url: "/election"},
//    {text: "Підключити організацію", url: ""},
//    {text: "Як подати звіт виборчого фонду?", url:""}
];

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[500]),
    width: '350px',
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
}))(Button);

const ColorButton_1 = withStyles((theme) => ({
  root: {
    color: yellow[500],
    width: '350px',
    backgroundColor: '#1C4860',
    '&:hover': {
      color: theme.palette.getContrastText(yellow[500]),
      background: yellow[700],
      border: '1px solid #ffd72f'
    },
    border: '1px solid white'
  },
}))(Button);


const ColorLink = withStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    fontWeight: '400',
    '&:hover': {
      color: '#FCDA4B',
    },
  },
}))(MaterialLink);



export function MainMenu(props) {

    const classes = useStyles();

    return (<div className={classes.root}>
          <div className={classes.inner}>
           <Grid container>
           <Grid item xs={4}><div className={classes.part1}>{menu_1.map(el=>el.url.match(/https/)? <a href={el.url} target="_blank" className={classes.external_link}><p>{el.text}</p></a>:<ColorLink component={RouterLink} to={el.url} underline="none">
<p>{el.text}</p></ColorLink>)}</div></Grid>
           <Grid item xs={4}><div className={classes.part2}>{menu_2.map(el=>el.url.match(/https/)? <a href={el.url} target="_blank" className={classes.external_link}><p>{el.text}</p></a>:<ColorLink component={RouterLink} to={el.url} underline="none">
<p>{el.text}</p></ColorLink>)}</div></Grid>
           <Grid item xs={4}><div className={classes.part3}>
              <a href="https://cabinet.politdata.nazk.gov.ua/" target="_blank"  style={ {'text-decoration': 'none' }}><p className={classes.button}><ColorButton>Кабінет</ColorButton></p></a>
            <a href="https://wiki.nazk.gov.ua/" style={ {'text-decoration': 'none' }}><p className={classes.button}><ColorButton_1>База знань НАЗК</ColorButton_1></p></a>
            <p><Grid container>
               <Grid item xs={6}>
               <p>Електронна адреса</p>
               <p>info@nazk.gov.ua</p>
               </Grid>
               <Grid item xs={6}>
               <div className={classes.site_link}>
               <p>Сайт НАЗК</p>
               <a href="https://nazk.gov.ua" className={classes.url_link}>
               <p>nazk.gov.ua</p>
               </a>
               </div>
               </Grid>
               <Grid item xs={12}>
               <p>
               <a href="https://www.facebook.com/NAZKgov" className={classes.ico_soc}><Facebook /></a> 
               <a href="https://www.youtube.com/channel/UCKwoUDbscWm4BT7BoBo0kMg/" className={classes.ico_soc}><YouTube /></a>
               <a href="https://twitter.com/NAZK_gov" className={classes.ico_soc}><Twitter /></a> 
               <a href="https://t.me/NAZK_gov_ua" className={classes.ico_soc}><Telegram /></a>
               </p>
               </Grid>
            </Grid></p>
           </div></Grid>
           </Grid>
           </div>
        </div>)
    
}