import React from 'react'
import { Route, HashRouter, Routes } from 'react-router-dom'
//import Main from './components/Main'
import Reports from './components/reports';
import ScrollToTop from './components/ScrollTop';
import { PartPage }  from './components/part_page';

import { ReportTempl }  from './components/report';

import Statboard from './components/statboard';

import  Election  from './components/elections';

import { MainPage } from './components/Mainpage';

import { About } from './components/About';

import { ReportTemplElection } from './components/report_election';

import Video from './components/dialogs/SwipeDialog';

export default props => (
    <HashRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path='/' element={ <MainPage /> } />
          <Route exact path='/reports' element={ <Reports /> } />
          <Route exact path='/partlist' element={ <PartPage /> } />
          <Route exact path='/reportview/:id' element={ <ReportTempl /> } />
          <Route exact path='/statboard' element={ <Statboard /> }/>
          <Route exect path='/election' element={ <Election type="TVK"/> } />
          <Route exect path='/election_pr' element={ <Election type="presidentialElections"/> } />
          <Route exect path='/election_prl' element={ <Election type="peopleDeputyElections"/> } />
          <Route exect path='/reportviewelection/:id' element={ <ReportTemplElection /> }/>
          <Route exect path='/about' element={ <About />}/>
          <Route exect path='/video' element={ <Video />}/>
        </Routes>
      </ScrollToTop>
    </HashRouter>
  )