import React, {useState, useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import { useNavigate } from "react-router-dom";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { get_stat_all_part, getInfoMainParty } from './actions';
import { CardActions } from '@material-ui/core';

import { get_reports_arch } from "./actions";

import {saveAs} from "file-saver";


import Grid from '@material-ui/core/Grid';

import SelectParty from './selectparty';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}


const header = [
    'Кількість поданих звітів',
    'Кількість не поданих звітів',
//    'Кількість несвоєчасно поданих звітів',
    'Загальна сума внесків (грошових коштів)',
    'Загальна сума отриманого державного фінансування',
    'Сума внесків від юридичних осіб',
    'Сума внесків від фізичних осіб',
    'Сума витрат',
    'Сума витрат на оренду приміщень',
    'Сума витрат на рекламу'
];

const keys = [
  'rep_accepts',
  'rep_unsend',
//  'rep_timeshift',
  'money_contribute',
  'money_goverment',
  'money_yur',
  'money_pers',
  'money_uses',
  'money_rent',
  'money_advert'];

const unit_name = [
  'одиниць',
  'одиниць',
//  'одиниць',
  'гривень',
  'гривень',
  'гривень',
  'гривень',
  'гривень',
  'гривень',
  'гривень'];

const unit_name_short = [
  'од.',
  'од.',
  //'од.',
  'грн.',
  'грн.',
  'грн.',
  'грн.',
  'грн.',
  'грн.',
  'грн.'];

const all_party_el = {
  "name": "Всі партії",
  "code": ""
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tabmain: {
      padding: '20px',
      margin: '20px'
  },
  formControl:{
      width: '200px'
  },
  values:{
    fontSize: '17px',
    fontWeight: '700',
    textAlign: 'right',
    paddingRight: '10%'
  }
}));

export  function Statinfo(props) {
  const classes = useStyles();

  const [data, setData] = useState({});

  const [isLoad, setIsLoad] = useState(false);

  const [quart, setQuart] = useState(null);

  const [partList, setPartList] = useState(null);

  const [partySelected, setPartSelect ] = useState(null);

  const [periods, setPeriods] = useState([]);


  useEffect(()=>{
    if(!quart) return;
    data_res(quart, partySelected && partySelected.code || null);
  },[quart]);


  useEffect(()=>{
    if(!partySelected) return;
    data_res(quart, partySelected.code);
  },[partySelected])


  useEffect(()=>{
    if(!all_party_el || partySelected) return;
    setPartSelect(all_party_el[0]);
   }, [all_party_el])


  const handleQuartSel = (e)=>{
        const value = e.target.value;
        setQuart(value);
  }

  const arr_periods_year = [
    { value: '2020', label: 'всі квартали 2020'}
  ];

  const setPeriodsAr = ()=> {

    const arr_periods_kv = [
    ];

    const curDate = new Date();  

    const startInt = 20211;

    const [year, month] = curDate.toISOString().split('-');

    const cur_kv = parseInt( (month-1)/12 * 4 +1 );


    let cur_period = { year: year, kv: cur_kv};


    while( parseInt(cur_period.year.toString() + cur_period.kv.toString()) >= startInt ) {
      
      arr_periods_kv.push({ value: cur_period.kv + "_" + cur_period.year, label: cur_period.kv + " квартал " + cur_period.year + " р." });
      
      cur_period.year = cur_period.kv > 1 ? cur_period.year : cur_period.year -1;

      cur_period.kv = cur_period.kv > 1 ? cur_period.kv - 1 : 4; 

    }


    setPeriods(arr_periods_kv);

    setQuart(arr_periods_kv[arr_periods_kv.length - 1].value);

    
  }



  const SelectQuart = ()=>{
  return <FormControl className={classes.formControl}>
    <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={quart}
    onChange={handleQuartSel}
    >
    
    {periods.map(row=><MenuItem value={row.value}>{row.label}</MenuItem>)}

    </Select>
  </FormControl>
  }

  
const load_party_list = async ()=>{
    
    const party = await getInfoMainParty();

    if(party && party.data) setPartList([all_party_el,...party.data]);

}

const selParty = (event, value) => {
  
  if(value && value.code != "") {
    //setData({});
    setPartSelect(value);
    //data_res(quart, value.code);
  } else {
    setPartSelect(all_party_el);
    //data_res(quart, null);
  }

};

const selPeriod = (event, value) => {
  
  if(value && value.code != "") {
    //setData({});
    //data_res(null, value.code);
  } 

};

const data_res = async (quart_in, party) => {


      if( ( !quart_in && !party )) return; 

      //setData({});

      const res = await get_stat_all_part(quart_in ? quart_in : quart, party ? party : partySelected ? partySelected.code : null);
      
      setData(res);
      setIsLoad(true);
    }

    const navigate = useNavigate();

    function preventDefault(event) {
      event.preventDefault();
    
      navigate('/reports' ) 
    }

    async function save_json(event){

      event.preventDefault();

      const file = await get_reports_arch( quart, partySelected);

      const file_name = `arch_${quart}.zip`;

      saveAs(file.data, file_name);

    }

  useEffect(()=>{
      if(isLoad) return;
      (async function part(){ await load_party_list(); setPeriodsAr();})();
      
  },[isLoad]);
  

  return (
    <div className={classes.tabmain}>
    <React.Fragment >
      <Title>Статистичні дані.</Title>
      <Table size="small">
        <TableHead>
        <TableRow>
            <TableCell colSpan={2}><InputLabel id="table-report-part-label">Партія</InputLabel><SelectParty data={partList} onChange={selParty} /></TableCell>
            <TableCell><InputLabel id="table-report-period-label" onChange={selPeriod}>Період</InputLabel><SelectQuart /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {header.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row}</TableCell>
              <TableCell>{unit_name_short[index]}</TableCell>
              <TableCell className={classes.values}>{data[keys[index]] ? new Intl.NumberFormat().format(data[keys[index]]) : "0"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container spacing={3}>
        <Grid item xs={6}>
            <div className={classes.seeMore}>
            <Link color="primary" href="#" onClick={preventDefault}>
              Переглянути звіти
            </Link>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.seeMore}>
          <Link color="primary" href="#" onClick={save_json}>
            Завантажити архів звітів (json)
          </Link>
          </div>
        </Grid>
      </Grid>

    </React.Fragment>
    </div>
  );
}