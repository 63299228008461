import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';

import CssBaseline from "@material-ui/core/CssBaseline";

import Container from '@material-ui/core/Container';

import ButtomBar from '../ButtomBar';

import { Statinfo } from "./table";

import { Header } from "../header";
import Footer from '../Footer';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowHover:{
    cursor: 'pointer',
  }
}));

export default function Statboard(props) {
 

 const { header, rows_data } = props;
 const [ rows, setRows ] = useState([]);

 const classes = useStyles();

 
 return (
    <React.Fragment>
      <Header short={true}/>
      <CssBaseline />
          <Container fixed>
            <Paper className={classes.table}>
            <Statinfo />
            </Paper>
          </Container>
        <ButtomBar />
      <Footer/>
    </React.Fragment>
  );
}
