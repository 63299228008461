import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  Label, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceArea, ResponsiveContainer,
} from 'recharts';


const initialState = {
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax+1',
  bottom: 'dataMin-1',
  top2: 'dataMax+20',
  bottom2: 'dataMin-20',
  animation: true
};

const styles = {
  grid:{
    width: '90%',
    marginLeft: '7%'
  }

}


class LineGraphMulti extends PureComponent {

  constructor(props) {
    super(props);

    const shLines = { showlines: props.line_info.map(el=>true) };

    this.state = Object.assign({},initialState, shLines);
  }


  handleSwitch = (e)=>{

    const { name, checked } = e.target;

    let newStateShLine = [...this.state.showlines];

    newStateShLine[ +(name.replace(/[A-z,-]+/, '')) ] = checked;
    this.setState( { showlines: newStateShLine } );

  }


  render() {
    const {
       barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2,
    } = this.state;


    const { data, line_info, ay_info, classes } = this.props;

    

    return (
      <div className="highlight-bar-charts" style={{ userSelect: 'none' }}>
      <ResponsiveContainer width='90%' aspect={21.0/7.0}>
        <LineChart
          
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            name="Дата"
            interval={22} 
            width={30}
          />

          { ay_info.map(y=><YAxis dataKey={y.key} yAxisId={y.aYiD} hide={y.hide}/>) }

          <Tooltip />
          { line_info.filter( (l,index) => this.state.showlines[index]).map(line=><Line type="natural" yAxisId={line.aYiD} dataKey={line.key} name={line.label} stroke={line.color} animationDuration={300} />)}
        </LineChart>

        </ResponsiveContainer>
        <Grid className={classes.grid} container spacing={3} alignItems="baseline" >
        <FormGroup row>
          {line_info.sort((a,b)=>{ if(a.label > b.label) return 1; if(a.label < b.label) return -1; return 0 } ).map( (le, index)=><Grid item xs={4}>
              <FormControlLabel
                control={<Switch checked={this.state.showlines[index]} onChange={this.handleSwitch} name={`line-${index}`} style={{color: le.color}} />}
                label={le.label}
              />
            </Grid> )
          }
        </FormGroup>
        </Grid>

      </div>
    );
  }
}

export default withStyles(styles)(LineGraphMulti);
