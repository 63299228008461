import React, { Component } from 'react';
import { Table } from '@material-ui/core';
import withStyles from "@material-ui/styles/withStyles";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import BlockIcon from '@material-ui/icons/Block';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { StatInfo } from './stat_info';

import '../../../../lib/jsformulacalculator';

import Paper from '@material-ui/core/Paper';





const styles = theme => ({
    table: {
      minWidth: 650,
    },
    head:{
        fontWeight:400,
        textAlign: 'center',
        fontSize: 'small'
    },
    container:{
        maxHeight: '85vh'
    },
    rowdata: {
        textAlign: 'center'
    },
    mainBlock: {
        marginTop: '30px'
    }
  });

  const limit_length = 35;

  function get_length(text){
      
        if(typeof text !== "string") return 0;
    
        let arr_words = text.split(' ');
        
        if(arr_words.length === 0) return 0;

        let len = 0;

        let length_cur = 0
        let arr_ind = 0;

        while(len < arr_words.length && length_cur < limit_length ){
                len += 1;
                length_cur += arr_words[arr_ind].length;
                arr_ind += 1;
        }
        
        return len;
  }

  function get_short(text) {

      const len = get_length(text);
      const arr_words = text.split(' ').slice(0,len);


      if( text.split(' ').length > len ) { 
          arr_words.push('...'); 
        }

      const res_text = arr_words.join(' ');

      return { short: res_text, full: text.split(' ').length > len ? text : '' }

  }

  function NormalCell(props){

      const { form, head, row, field} = props;

      
      if(form && form.fields) return (
          form.fields.map(
              field=> {
                const value_cell = row[field] && ( ( (typeof row[field] ) === "string" && !row[field].match(/\*+/g) ) || typeof row[field]  === "number" ) ? row[field]: row[field] && (typeof row[field] ) === "string" && row[field].match(/\*+/g) ? <BlockIcon /> : '';
                return <div style={{textAlign: 'center'}} > <div style={{ marginTop: '5px'}} >{ head.find(hd=>hd.key === field).name }: </div> <div></div><b>{value_cell}</b></div>
            }
          )
      )

     
      if(!form) return <div>{ row[field] }</div>

  }


  class TabletInfo extends Component {

    constructor(props){
        super(props);
        this.state = {
            statisticView: props.data && props.data[props.key_table] && Array.isArray(props.data[props.key_table]) && props.data[props.key_table].length > 0
        };
    }

    handleChangeStatistic = () =>{
        this.setState({ statisticView: !this.state.statisticView});
    }



    render(){

        const { head, data, classes, form, statistic } = this.props;

        const head_norm = [];

        const stopFieldsComb = form && form.hasOwnProperty('combine') ? form.combine.map(cmb=>
            {
                return cmb.fields.map(el_cmb=>el_cmb)
            }) : [];

        let stopFields = [];
        stopFieldsComb.forEach(el=>{
            stopFields = [...stopFields, ...el]
        });

        // head_norm[head_norm.findIndex( el=> el.id === combine.name)]
        
        head.forEach(el=>{
            const combine = form.combine && form.combine.find(cmb=>!!cmb.fields.find( cm_el=> cm_el === el.key) );

            if( !stopFields.find(stp=>stp === el.key) && !combine ) head_norm.push(Object.assign(get_short(el.name), { id: el.key }) );
            if( stopFields.find(stp=>stp ===el.key) && (!head_norm.find(head_el=>head_el.id === combine.name ) ) ) head_norm.push(Object.assign(get_short(combine.name), { id: combine.name }) );
        });


        
        if(!this.props.show) return <div/>;
        if(!data[this.props.key_table]) return <div>В звіті відсутній розділ {this.props.key_table} </div>;

        const  TableDetail = (props) => {return props.show ? <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} aria-label="simple table" stickyHeader={true}>
                    <TableHead >
                            <TableRow 
                            >
                                { head_norm.map((el, index_el)=><Tooltip key={index_el} title={el.full} arrow><TableCell className={classes.head} >{el.short}</TableCell></Tooltip>) }
                            </TableRow>
                    
                    </TableHead>
                    <TableBody>{ data && data[this.props.key_table].map( (el, index) =><TableRow 
                                key={index}
                                hover
                                onClick={(event) => {} }
                            >{ head_norm.map((cell, index_cell)=><TableCell key={`${index}-${index_cell}`} className={classes.rowdata}component="th" scope="row"><NormalCell
                                        row={el} 
                                        head={head} 
                                        field={cell.id} 
                                        form={form && form.hasOwnProperty('combine') ? form.combine.find(far=> far.name === cell.id ) : null } /> 
                                </TableCell>)}</TableRow>
                            )}
                            { data && data[this.props.key_table].length == 0 ?<TableRow>{
                                head_norm.map((cell, index_m)=><TableCell key={index_m} component="th" scope="row">-</TableCell>)
                                }</TableRow>:''}
                    </TableBody>
                </Table>
            </TableContainer>:<div/>}


        return (
            <div>
                 <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.statisticView}
                            onChange={this.handleChangeStatistic}
                            name="statistics"
                            size="medium"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            disabled={ !( data && data[this.props.key_table] && Array.isArray(data[this.props.key_table]) && data[this.props.key_table].length > 0 ) }
                    /> }
                    label = { !this.state.statisticView ? "Показати статистику":"Показати детально" }
                    />
                <Paper className={classes.mainBlock}>
                    <TableDetail show={!this.state.statisticView} />
                    <StatInfo show={this.state.statisticView} data={data} statistic={statistic} />
                </Paper>
           
            </div>
        )
    }
}


export default withStyles(styles)(TabletInfo)