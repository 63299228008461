const params = require('../../conf/api.json');

const paramApi = params.paramApi;

const url_s="/api/getallreportsshort";


function createData(name, type, year, period, types, date, key, id, partyCode, parentCode, result, resultstatus) {
    return { name, type, year, period, types, date, key, id, partyCode, parentCode, result, resultstatus };
}


function convertData(data){
    return data.map( r=>createData(
    r.partyName, 
    r.officeType, 
    r.year, 
    r.period, 
    r.types, 
    !isNaN( (new Date(r.date) ).getTime()) && new Date(r.date).toLocaleDateString('uk') || r.date, 
    r.key, 
    r.id, 
    r.partyCode, 
    r.parentCode, 
    r.result,
    r.result && r.result.date ?  " Завершено" : "На розгляді"
    ) )};


function getReports(query){

    const {filters, orderBy, orderDirection, search} = query;

    const params = filters.map(f=>`filter=${f.column.field}^${f.value}`);

    if(orderBy && orderBy.field) params.push(`sort_by=${orderBy.field}`);
    
    if(orderDirection) params.push(`sort_d=${orderDirection}`);

    if(search) params.push(`search=${search}`);

    params.push(`page=${query.page + 1}`);
    params.push(`per_page=${query.pageSize}`);

            return   new Promise((resolve, reject) => {
                let url =`${url_s}?`;
                url += params.join('&');

                fetch(url, paramApi)
                  .then(response => response.json())
                  .then(result => {
                    resolve({
                      data: convertData(result.data),
                      page: result.page - 1,
                      totalCount: result.total,
                    })
                  })
              })
}


function getReports2(query){


    new Promise((resolve, reject)=>{
        resolve(convertData([]));
    });
}


function getReports1(query) {


    return new Promise((resolve, reject) => {
        let url = url_s + '?';
        url += 'per_page=' + query.pageSize
        url += '&page=' + (query.page + 1)
        url += query.filters.map
    try{
        fetch(url, Object.assign({}, paramApi))
          .then(response => response.json())
          .then(result => {
            resolve({
              data: result.data,
              page: result.page - 1,
              totalCount: result.total,
            })
          })
    }
    catch (error){
            reject(error);
    }
    })
}

export default getReports;