import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import ListSubheader from '@material-ui/core/ListSubheader';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';


import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { ReportMainInfo } from './components/maininfo';

import TabletInfo from './components/regionoffices';

import { get_reestr_data } from './actions';
const logo = require("../../images/nazk_logo_2020.svg");

const schema_old = require('./data/schema.json');
const schema_2020 = require('./data/schema_october_2020.json');

let schema = {};

let head_table = {};

const tables_list = [];

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    color: 'white',
    backgroundColor: 'black'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#122945',
    color: 'white'
  },
  // necessary for content to be below app bar
  toolbar: {
    position: 'relative',
    fontSize:'21px',
    minHeight: '60px'
  },
  toolbar_text: {
    top: '22px',
    fontSize: '18px',
    position: 'absolute',
    right: '25px'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    width: `calc(100% - ${drawerWidth}px)`,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));

export function ReportTempl(props) {

  const [size, setSize] = useState({
    width: "auto",
    height: "auto"
  });

  const classes = useStyles();

  const [ data, setData ] = useState('');

  const [ active, setActive ] = useState(0);

  const [menu, setMenu] = useState({});

  //const menu = createMenu();

  const { id } = useParams();

  let stateSubmenu = {};

  for(let index=0; index < menu.length; index ++){
    if( menu[index].hasOwnProperty('super') ) stateSubmenu[ menu[index]['super'] ] = false;
  }

  const [ openMenu, setSubmenu] = useState(stateSubmenu);
  

  const title_to_return = "Повернутись до всіх звітів";

  const title = [ 
    schema.description, 
    data.partyName, 
    data.officeType 
  ].join(' - ')

  const period = [data.period, data.year, data.year ? 'року': null].join(' ');

  const fontSize = title.length > 90 ? { fontSize: '12px' } : title.length > 65 ? { fontSize: '15px' } : {};


  useEffect(()=>{

    if(!data) get_reestr_data(id)
    .then( data=> {
      if(!data.data.paymentCostsPaymentReturn) {
        schema = schema_2020;
      }else{
        schema = schema_old;
      };
      setData(data.data);
      setMenu(createMenu); 
      head_table = getHead()
      return data.data;
    } )
    .catch( err=> console.log(err));
  }, []);

  const handleOpenSubMenu = (name) => {
    setSubmenu(prev=>( { ...prev, [name]: !openMenu[name] } ) )
  }


  if(Object.keys(menu).length < 1 ) return <div/>;
  if(Object.keys(Object.keys(schema.schema) ).length < 1 ) return <div/>;
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <NavLink to='/reports'>
              <Tooltip title={title_to_return} arrow>
                <KeyboardBackspaceIcon style={{color: "white", verticalAlign: 'text-bottom', marginRight:'20px'}}/>
              </Tooltip>
            </NavLink> 
             <span style={fontSize}>{title}</span>
             
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >

       <div style={{ position: "sticky", display:'grid', top:'0px', zIndex: '100', backgroundColor:'#122945'}}> <div className={classes.toolbar} ><NavLink to='/'><img style={{ width: '37px', marginLeft: '19px', marginTop: '12px'}} src={logo}/></NavLink> <span className={classes.toolbar_text}>{period}</span> </div></div>
        <Divider />
        <List>
          {menu.map((el, index_main) => {
              
              const text = el.description;

              const index = el.position;

              if(!el.super)return (
                <ListItem
                  key={index_main} 
                  button key={text} 
                  onClick={()=>setActive(index)}
                  selected={ active === index}
                >
                  <ListItemText primary={text} style={{color: active === index ? '#ffe565':null}}/>
                </ListItem>
              );
              
              return (
                <div key={index_main}>
                  <ListItem key={index_main} button onClick={()=>handleOpenSubMenu(el.super)} >
                    <ListItemText primary={<span style={{ fontWeight: openMenu[el.super] ?'700': null}} >{el.super}</span>} />
                      {openMenu[el.super] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={openMenu[el.super]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {    el.items.map((el_l, index)=> ( <ListItem key={index} button style={{ paddingLeft: '20px'}} selected={ active === el_l.position}>
                      <ListItemText 
                        primary={el_l.description} 
                        onClick={()=>setActive(el_l.position)}
                        style={{color: active === el_l.position ? '#ffe565':null}}
                        />
                    </ListItem> )
     
                    )}
                    </List>
                  </Collapse>
                </div>
              );
            } ) 
         }
        </List>
        <Divider />
        
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <ReportMainInfo 
            show={ active === 0 }
            data = {data}
          />

          {tables_list.slice(1).map((el, index_l) => {
              return (
                <TabletInfo
                  key={index_l}
                  show={ active === el.position }
                  head = { getHead(el.id) }
                  form = { schema.schema.properties[el.id].form || {} }
                  statistic = { schema.schema.properties[el.id].statistic || [] }
                  data = {data}
                  key_table = { el.id }
              />    
              );

            } ) 
          }
           
      </main>
    </div>
  );
}

function getHead(key) {

  if (!schema.schema.properties[key]) {
    return [];
  }
  
  const info = schema.schema.properties[key].items.properties;

  if(!info) return [];

  const result = Object.keys(info).map(el=> { return {name: info[el].description, key: el} } );

  return result;

}

function createMenu() {

    if(!schema.schema) return;

    const properties = schema.schema.properties;
    
    const menu = [];

    const menu_gen = {id: "general", description: 'Загальна інформація', position: 0 };
    
    menu.push(menu_gen);

    let superMenu = '';

    Object.keys(properties).forEach(key=>{
        if( properties[key] && properties[key]['menu']  ) {
          
          if( properties[key]['menu'] && properties[key]['menu']['super']) {
            
            if(properties[key]['menu']['super'] != superMenu) menu.push( { super: properties[key]['menu']['super'], items: [] } );

            superMenu = properties[key]['menu']['super'];

            menu[ menu.length -1 ].items.push( { "id": key, description: properties[key]['menu']['description'], position: properties[key]['menu']['position'] } );

          } else {
          
          menu.push( { "id": key, description: properties[key]['menu']['description'], position: properties[key]['menu']['position'] } );
          }

        }
    });

    const menu_sort = menu.sort( (a,b)=>{ if(a.position > b.position ) return 1; if(a.position < b.position ) return -1; return 0; } );

    tables_list.length = 0;

    menu.forEach(el=>{
    
      if(!el.items) {
        tables_list.push(el);
      } else {
        el.items.forEach( sub=>tables_list.push(sub) )
      }
  
    });

    return menu_sort;
}

//{ this.state.data != '' ? <RealEstate data={this.state.data} /> :'' }
