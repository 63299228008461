import React from 'react';

import CssBaseline from "@material-ui/core/CssBaseline";
import {Header} from "./header";
import PartInfo from "./part_info";
import Footer from './Footer';

import '../../src/assets/css/styles.css';

export function PartPage(props){
    return (
        <>
        <CssBaseline />
        <Header short={true}/>
        <div className="table_view">
        <PartInfo />
        </div>
        <Footer />
        </>
    )
}