import './header.css';
import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { CentralHeader } from './central/central';

import { news_notification } from './lib/news_notif';

import { statInfo } from '../../actions/statistics';

import Box from '@material-ui/core/Box';

import menuIcon from './menu.svg';
import logoIcon from './img/logo_white.svg';
import { BottomHeader } from './bottom/bottom';
import { makeStyles, Grid, withStyles } from '@material-ui/core';

import { Button } from '@material-ui/core';

import { yellow } from '@material-ui/core/colors';

import { MainMenu } from '../menu';


import { ReactComponent as EyeNazk } from './img/eye_nazk.svg';

import { UpperMenu } from './uppers';

const ColorButton = withStyles((theme) => ({
    root: {
      color: 'white',
      width: '150px',
      backgroundColor: '#305882',
      '&:hover': {
        color: theme.palette.getContrastText(yellow[500]),
        background: yellow[700],
        border: '1px solid #ffd72f'
      },
      border: '1px solid white'
    },
  }))(Button);

const st_headers = {
    root:{
        fontFamily: "Ubuntu, sans-serif",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "17px",
        color: "#FFFFFF",
        position: 'relative'
    },
    blue_b: {
        
    },
    '@keyframes opacity' : {
        'from': {
            backgroundPosition: 'calc(50% - 75px) center, calc(50% - 250px) center, calc(50% - 475px) center, calc(50% - 675px) center, calc(50% - 875px) center',
        },
        to: {
            backgroundPosition: 'calc(100% - 0px) center, calc(100% - 125px) center, calc(100% - 270px) center, calc(100% - 415px) center, calc(100% - 530px) center',
       }
      },
    box:{
        paddingRight: "0px",
    //        background: "radial-gradient(50% 88.89% at 50% 44.72%, #4A96C4 0%, #3973A1 100%)",
    background: "linear-gradient(90deg,#4376B3 0%,#477DBD 34.9%,#234260 100%);",
        '& ::before': {
            display: 'none'

        }
    },
    h_body:{
        paddingLeft: "5px",
        maxWidth: '1280px',
        margin: 'auto'
    },   
    logo_bloc:{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo_list:{
        maxWidth: '190px',
        marginRight: '9px',
        paddingRight: '15px',
        borderRight: '1px solid #fff',
        '& ul':{
            padding: '5px 0px',
            listStyle: 'none',
            textAlign: 'right',
            margin: 0,
            '& li':{
                display: 'inline-block',
                padding: '3px 4px',
                marginBottom: '2px',
                color: '#212121',
                fontSize: '10px',
                lineHeight: '10px',
                fontWeight: 700,
                textTransform: 'uppercase',
                background: '#fff',
                borderRadius: '2px'
            }
        }
    },
    first_h:{
        padding: '20px 0px 20px 0px',
        alignItems: 'center',
        fontSize: "12px",
    },
    logo_img:{
        maxHeight: '49px',
        display: 'flex'
    },
    logo_n:{
        maxWidth: '257px',
        paddingLeft: '15px',
        display: 'none',
        '& h2':{
            fontSize: '11px',
            lineHeight: '17px',
            fontWeight: 400,
            letterSpacing: '2px',
            textTransform: 'uppercase'
        }
    },
    logo_link:{
        color: 'inherit',
        textDecoration: 'none'
    },
    notif_block:{

    },
    central_header: {
        paddingTop: '26px'
    },
    bottom_header: {
        marginTop: '56px',
        

    }
};


export function Header(props) {

    const location = useLocation();

    const { short } = props;

    if(short) delete st_headers['blue_b'];


    const useStyles = makeStyles( (theme)=>{
        st_headers.logo_n[[theme.breakpoints.up('md')]] = {
            display: 'initial'
        };

        st_headers.h_body[[theme.breakpoints.up('md')]] = {
            paddingLeft: "75px"
        };

        st_headers.box[[theme.breakpoints.up('md')]] = {
            paddingRight: "75px"
        };

        st_headers.central_header[[theme.breakpoints.up('md')]] = {
            borderTop: '1px solid rgba(255, 255, 255, 0.4)',
            paddingTop: '56px'
        }


        return st_headers;
    });

    const classes = useStyles();


    const [anchorEl, setAnchorEl] = useState(null);

    const [statistics, setStatistics] = useState(null);

    const [openNotification, setOpenNotification] = useState(false);

    const [elNotif, setElemNotif] = useState(null);

    const [loadNotification, setLoadNotification] = useState(false);

    const openMenu = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    useEffect(()=>{
        return;
        if(statistics !== null) return;
        statInfo( setStatistics );
    },[statistics]);

    useEffect(()=>{
        if(open) setAnchorEl(null);
    },[location]);


    useEffect(()=>{
        if(loadNotification) return;
        news_notification();
        setLoadNotification(true);
    });

    useEffect(()=>{
        if(!elNotif) setElemNotif(document.getElementsByClassName("header__nofitications-dropdown")[0]);
        
        if(!elNotif) return;

        if(openNotification) elNotif.style.display = 'flex';

        if(!openNotification) elNotif.style.display = 'none';

    },[elNotif, openNotification])

    const onClickOpenNotif = ()=> setOpenNotification(!openNotification);



    return(
        <div className={ classes.root} >
        <div className={classes.blue_b}>
        <Box  className={classes.box} >
        <UpperMenu />
        <div className={classes.h_body}>
            <Grid container direction="row" alignItems="center" justify="center" className={classes.first_h}>
                <Grid item xs={2} md={3} alignItems="left">
                    <div className="menu_bloc" onClick={openMenu}>
                        { false && <img src={menuIcon}/>}
                        <div className={"header__burger-btn" +  (anchorEl != null ? " active" : "") }><span></span><span></span><span></span></div>
                        <span id="t_menu">МЕНЮ</span>
                    </div>
                </Grid>
                <Grid item xs={6} md={6}>
                    <a href="/" className={classes.logo_link}>
                    <div className={classes.logo_bloc}>
                        <div className={classes.logo_list}>
                            <ul>
                                <li>Єдиний державний реєстр</li>
                                <li>звітності політичних партій</li>
                            </ul>
                        </div>
                        <div className={classes.logo_img}>
                            <img className="bg" src={logoIcon} alt="Національне агентство з питань запобігання корупції"/>
                        </div>
                        <div className={classes.logo_n}>
                            <h2>Національне агентство <br/>з питань запобігання корупції</h2>
                        </div>
                    </div>
                    </a>
                </Grid>
                <Grid item xs={3} alignItems="right" justify="right">

                <div style={{ textAlign: "right"}}><a href="https://cabinet.politdata.nazk.gov.ua/" target="_blank"  style={ {'text-decoration': 'none' }}><p><ColorButton>Кабінет</ColorButton></p></a></div>

                {false && <div className="header-control">
                    
                    { false && <span className="eye-nazk-block nazk-lang" >
                        <a href="javascript:;" className="header__buttons-btn">
                            ENG
                        </a>
                    </span> }
                    
                    
                    <span className="eye-nazk-block" >
                    <a href="javascript:;" className="header__buttons-btn">
                        <EyeNazk className="eye-nazk"/>
                    </a>

                    </span>
                    

                    <div className="header__buttons-btn data_notify" ></div>
                     
                    <span className="hc-link-text" style={{ display: "none"}}>Новини</span>
                    <button href="" className="btn hc-link hc-search">
                        <i className="fas fa-search"></i>
                        <span className="hc-link-text">Пошук</span>
                    </button>
			    </div>}
                    
                </Grid>
            </Grid>
            { open && <div className="menu-open">
                    <MainMenu />
            </div> }
            {!short  && <div className={classes.central_header}><CentralHeader /></div>}
            {!short && <div className={classes.bottom_header}> <BottomHeader data={statistics}/></div> }
        </div>
        </Box>
        </div>
        </div>
    )
}