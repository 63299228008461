
const params = require('./../../../conf/api.json');

const paramApi = params.paramApi;

let isFetching = false;

const url="/api/stat_all_part/:kv";

const url_record="/api/getreportzip/:id";

const url_attach="/api/getreportattach/:id";

const url_rep_arch = "/api/getperiodrep";


export async function get_stat_all_part(kv, code) {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching =  true;

    const result = await fetch(url.replace(":kv", kv + (code && `?code=${code}` || '')), Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
    
}


export async function get_reports_arch(kv_year, code) {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching = true;

    const [kv, year] = kv_year.split('_');

    const monts_f = [1,4,7,10];

    const monts_l = [3,6,9,0];

//    const date_from = `${year}-${monts_f[+kv]}-1`;

//    const date_to = new Date( +kv == 4 ? +year + 1 : year, monts_l[+kv], 0 ).toISOString().split('T')[0];

    //const query = `?date_from=${date_from}&date_to=${date_to}&code=code`;
    
    const query = `?period=${kv}&year=${year}`;

    const result = await fetch(url_rep_arch + query, Object.assign({}, paramApi) );
    const data = await result.blob();  

    isFetching =  false;

    return {data: data, name: result.headers.get('content-disposition')};

}


export async function get_reestr_data(id) {
    paramApi.method = "GET";

   // window.get_crypto('https://eu.iit.com.ua/sctest/user.php', 'JSON')


    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(url_record.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
}

export async function get_report_as_attach(id) {

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(url_attach.replace(':id', id), Object.assign({}, paramApi) );
    const data = await result.blob();  

    isFetching =  false;

    return {data: data, name: result.headers.get('content-disposition')};

}

export async function getInfoMainParty() {
    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching = true;

    const result = await fetch(params.main_api.url + '/getpartylistmain', Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;
    
    return data; 
}

