import React from "react";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

import "./styles.css";

import bottomBgImage from "./../../assets/img/top_bg.jpg";
import fbIcon from "./../../assets/img/fb_icon.svg";
import youtubeIcon from "./../../assets/img/youtube_icon.svg";
import twitterIcon from "./../../assets/img/twitter_icon.svg";
import tgIcon from "./../../assets/img/tg_icon.svg";

const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "footer-text",
          },
          style: {
            color: "#fff",
            fontSize: 13,
          },
        },
        {
          props: {
            variant: "licence-text",
          },
          style: {
            color: "#fff",
            fontSize: 13,
            padding: 30,
            borderTop: "1px solid rgba(255, 255, 255, 0.46)",
            marginTop: 30,
            textAlign: "center",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: {
            variant: "email-link",
          },
          style: {
            color: "#fdc229",
          },
        },
        {
          props: {
            variant: "licence-link",
          },
          style: {
            color: "#ffffff",
            fontSize: 13,
            fontWeight: 700,
          },
        },
      ],
    },
  },
});
const Footer = () => {
  return (
    <Container
      component='footer'
      maxWidth='100%'
      sx={{
        backgroundImage: `url(${bottomBgImage})`,
        backgroundSize: "cover",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginTop: "30px"
      }}>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth='xl'
          sx={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant='footer-text'>
                Україна, м. Київ, бульвар Миколи Міхновського, 28, 01103;
                <br />
                e-mail:
                <Link variant='email-link' href='mailto:info@nazk.gov.ua'>
                  info@nazk.gov.ua
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} textAlign='right'>
              <Typography variant='footer-text' component='p'>
                Офіційні сторінки НАЗК:
                <Link href='https://www.facebook.com/NAZKgov'>
                  <img src={fbIcon} alt='НАЗК Facebook'></img>
                </Link>
                <Link href='https://www.youtube.com/channel/UCKwoUDbscWm4BT7BoBo0kMg/'>
                  <img src={youtubeIcon} alt='НАЗК Youtube'></img>
                </Link>
                <Link href='https://twitter.com/NAZK_gov'>
                  <img src={twitterIcon} alt='НАЗК Twitter'></img>
                </Link>
                <Link href='https://t.me/NAZK_gov_ua'>
                  <img src={tgIcon} alt='НАЗК Telegram'></img>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='licence-text' component='div'>
                Весь контент доступний за ліцензією{" "}
                <Link
                  variant='licence-link'
                  href='https://creativecommons.org/licenses/by/4.0/legalcode'>
                  Creative Commons Attribution 4.0 International license
                </Link>
                , якщо не зазначено інше
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Container>
  );
};

export default Footer;
