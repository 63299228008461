import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Paper from '@material-ui/core/Paper';

import EnhancedTable from '../reportlist';

import { get_election_record_short } from './actions';

import '../../table_party.css';

import Container from '@material-ui/core/Container';



import { Header } from "../header";

import Footer from "../Footer";

const type_titles = {
    'TVK': "Місцеві вибори",
    'presidentialElections': "Президентські вибори",
    'peopleDeputyElections': "Парламентські вибори"
}

const sub_titles = {
    'TVK': "/Найменування місцевої організації партії",
    'presidentialElections': "",
    'peopleDeputyElections': "/Найменування політичної партії/Найменування місцевої організації партії"
}


const styles = theme => ({
    table: {
      maxWidth: 1650,
      minHeight: 200,
      marginTop: 20,
    },
    head:{
        fontWeight:700
    }
  });



//name, type_election, date_election, type_report, date_created, analitics, documentId, report_number:''




function Election(props) {

    const location = useLocation();

    const [data, setData] = useState([]); 

    const { classes, type } = props;

    const headerTables = [
        {field: 'name',  title: 'ПІБ кандидата' + sub_titles[type]},
        {field: 'type_election',  title:'Тип виборів'},
        {field: 'date_election',  title:'Дата проведення', customSort: (a,b)=>{
    
            if(!a.date_election || !b.date_election) return 0;
    
            const _as = a.date_election.split('.');
            const _bs = b.date_election.split('.');
    
            const _a = new Date(_as[2], _as[1]-1, _as[0]).getTime();
            const _b = new Date(_bs[2], _bs[1]-1, _bs[0]).getTime();
    
            if (_b < _a) {
                return -1;
              }
            if (_b > _a) {
                return 1;
            }
            
            return 0;
    
        }},
        {field: 'type_report',  title:'Вид звіту'},
        {field: 'date_created', title: 'Дата надходження звіту', customSort: (a,b) =>{
    
            if(!a.date_created || !b.date_created) return 0;
    
            const _as = a.date_created.split('.');
            const _bs = b.date_created.split('.');
    
            const _a = new Date(_as[2], _as[1]-1, _as[0]).getTime();
            const _b = new Date(_bs[2], _bs[1]-1, _bs[0]).getTime();
    
            if (_b < _a) {
                return -1;
              }
            if (_b > _a) {
                return 1;
            }
            
            return 0;
        }},
        {field: 'analitics',  title:'Аналіз'}
    ];
    

    const getData = ()=>{
        get_election_record_short(type)
        .then( data=> data.data.sort( (a,b) =>{
            if(new Date(a.date_created) < new Date(b.date_created) ) return 1;
            if(new Date(a.date_created) > new Date(b.date_created) ) return -1;
            return 0;
        }) )
        .then( data=>data.map( 
            (row,indx)=>{ row['key']=indx;
            row['resultstatus'] = row.result && 
                row.result.date ? 
                    new Date (row.result.date).toLocaleDateString('uk') : row.date !== "-- -- --" ? 'На розгляді': "-"; if(row.date == "-- -- --") row.types = "Додаток"; return row;} ))
        .then( data => setData(data))
        .catch( err=> console.log(err));
    }

    useEffect(()=>{
        getData();
    },[location]);
    

       

        return(
        
        <React.Fragment>
            
            <CssBaseline />
        <Header short={true} />
        <Container>
            <Paper className={classes.table}>
            <EnhancedTable 
                header = {headerTables}
                rows_data = {data.map(row=>{
                    row['date_created'] = new Date(row.date_created).toLocaleDateString('uk');
                    return row;
                })}
                title={type_titles[type]}
                detailview="/reportviewelection"
                key_id="id"
            />
        </Paper>
        </Container>
        <Footer />
        </React.Fragment>
    )
}

export default withStyles(styles)(Election);