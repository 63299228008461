import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BaseDialog from './BaseDialog';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from 'react-swipeable-views-utils';

import { YouTubeControl } from '../video/youtubeplay';

import { Header}  from '../header';
import Footer from '../Footer';

const logo = require('../../images/full_logo.svg');

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);






const videosSteps = [
  {
    label: "Реєстрація: теоретична частина",
    description: "",
    id_video: "KbWQLs4zYRY" 
  },
  {
    label: "Реєстрація",
    description: "",
    id_video: "xB4V09dnFJw"
  },
  {
    label: "Кабінети керівника, уповноваженої особи та користувача",
    description: "",
    id_video: "sDsrMTDonpk"
  },
  {
    label: "Управління організацією (користувачі, дії з партіями)",
    description: "",
    id_video: "Byi7p6ZST_4"
  },
  {
    label: "Робота зі звітом (створення, заповнення, відправка на погодження)",
    description: "",
    id_video: "cyKR9Y_pW2I"
  },
  {
    label: "Розгляд, доопрацювання, погодження і направлення звіту до НАЗК",
    description: "",
    id_video: "qWI726CJ2UA"
  }
]

const tutorialSteps = [
  {
    label: 'A first label',
    description: 'This is the first item on the label',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'A third label',
    description: 'This is the third item on the label',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'A fifth label',
    description: 'This is the fifth item on the label',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Other label',
    description: 'This is other label',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

const styles = theme => ({
  container: {
    maxWidth: 800,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  stepsContainer: {
    marginLeft: 72,
    textAlign: 'left',
    marginTop: 20,
    height: 65,
    fontSize: "small"
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: "x-large",
    color: "#303f9f",
    margin: "8px"
  }
});

class SwipeDialog extends Component {

  state = {
    activeStep: 0
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;
    const maxSteps = videosSteps.length;
    const { activeStep } = this.state;
    return (

        <div >
        <Header short={true}/>
          <div style={ { textAlign: "center", padding: "30px 100px" } }>
          <div className={classes.gutterBottom}>
            <div className={classes.title}>Відео інструкції для користувачів</div>
          </div>
          <div>
            <SwipeableViews
              axis='x'
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {videosSteps.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <YouTubeControl id_video={step.id_video}/>
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <div>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              nextButton={
                <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                  Наступне
                </Button>
              }
              backButton={
                <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                  Перед цим
                </Button>
              }
            />
            </div>
          </div>
          <div className={classes.stepsContainer} style={{ textAlign: "center" }}>
            <Typography style={{textTransform: 'uppercase'}} color='secondary' gutterBottom>
              {'Відео ' + (activeStep + 1) + ' - ' + videosSteps.length + '.  ' + videosSteps[activeStep].label}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {videosSteps[activeStep].description}
            </Typography>
          </div>
        </div>

        <Footer />
          
        </div>
        
    )
  }
}

export default withStyles(styles)(SwipeDialog);
