/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SelectParty(props) {
  const classes = useStyles();

  const { data, onChange, default_val } = props;

  if(!data) return null;

  return (
    <Autocomplete
      id="country-select-demo"
      //style={{ width: 300 }}
      options={data}
      classes={{
        option: classes.option,
      }}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => `${option.name} ${option.code}`}
      defaultValue={ data[0] }
      renderOption={(option) => (
        <React.Fragment>
          {option.name} {option.code} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          //variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}