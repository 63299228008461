import React from 'react';

import {Header} from './header';
import Content from './Mainpage/index';
import Footer from './Footer';

export function MainPage(props){
    return (<div>
        <Header short={true} full={false}/>
        <Content />
        <Footer />
      </div>
      )
}