import React, { useEffect, useState } from 'react';

import {PieChart, Pie, Sector, Cell} from 'recharts';

import  ActivePie  from './shapes/activePie';

import { makeStyles } from '@material-ui/core/styles';

import { CounterAnimated } from '../../../animated/counter';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import LineGraphMulti from './shapes/lineGraphMulti';

import * as Stformula from './statistics_formulas';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      //maxWidth: 800,
      backgroundColor: theme.palette.background.paper
    },
    item: {
    },
    grid: {
        paddingLeft: '30px',
        paddingTop: '7px'
    },
    grid_diag: {
        maxWidth: '80%',
//        minHeight: '500px'
    },
    gridItems: {
        fontSize: 'x-large',
        fontWeight: 'lighter'
    },
    resultGrid: {
        color: '#4090c0',
    },
    diagramm: {
        textAlign: 'center',
        fontSize: 'xx-large',
        marginRight: '111px',
        color: '#122945',
        paddingTop: '30px',
        paddingBottom: '15px'
    }
  }));


/**  props - statistic [] { label String, formula []{ name String, ?return_type String, ?label []String } }
           - data
*/
export function StatInfo(props) {

    const classes = useStyles();

    const { statistic, data, show } = props;

    const info_no_data = 'В звіті відсутня інформація по розділу.',
          info_no_statistic = 'Статистичний модуль для цього розділу ще в розробці. Можна переглянути детальну інформацію.'

    const COLORS = [], RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    if(!show) return <div/>;

    if(!Array.isArray(statistic) || statistic.length < 1 ) return <div>{info_no_statistic}</div>;
 
    return(
        <div>
        { statistic.map(el=>{
            const {label, formula} = el, 
                  formula_name = formula[0],
                  return_el = formula.length > 1 && formula[1] === "object" ? formula[2] : [];

            const type_elem = return_el.length == 0 ? ["string"] : formula[3];

            const result_statistic = Stformula[formula_name](data);

            const diagram = [];

            let ay_info = [], line_info = [], DiagType = {};

            switch (type_elem[0]) {
                case "string":
                break;
                case "diagram_line":
                    DiagType = LineGraphMulti;
                break;
                case "diagram_multi_circle":
                    DiagType = ActivePie;
                break;
            }

            if(return_el.length > 0 && type_elem[0] === "diagram_multi_circle" ) {
                const level_1 = Object.keys(result_statistic);

                level_1.forEach(level_1_el=>{
                    const level_2 = Object.keys(result_statistic[level_1_el]);
                    level_2.forEach(level_2_el=>{
                        formula[2].forEach( (el, index) => {
                            const obj_ins = { property: level_1_el };
                            obj_ins['type'] = level_2_el;
                            obj_ins['value'] = result_statistic[level_1_el][level_2_el][el.key];
                            obj_ins['name'] = el.label;
                            obj_ins['dimension'] = el.dimension; 
                            if(!diagram[index]) diagram[index] = [];
                            diagram[index].push(obj_ins);
                        } );
                    });
                });

            }

            if(return_el.length > 0 && type_elem[0] === "diagram_line" ) {

                const add_days_info = [];

                for (let st = result_statistic.date_period_from.getTime(); st < result_statistic.date_period_to.getTime(); st += 24*60*60*1000){
                    if(!result_statistic.dateDistribObj[st]) result_statistic.dateDistribObj[st]={ company: { costs: 0, count: 0, revers: 0 }, person: { costs: 0, count: 0, revers: 0 } };
                }

                const arrData = Object.keys( result_statistic.dateDistribObj ).sort().map(date_key=>{ 
                    return {
                    date: (new Date(+date_key)).toLocaleDateString('uk'),
                    company_costs: result_statistic.dateDistribObj[date_key].company.costs,
                    company_count: result_statistic.dateDistribObj[date_key].company.count,
                    person_costs: result_statistic.dateDistribObj[date_key].person.costs,
                    person_count: result_statistic.dateDistribObj[date_key].person.count,
                    all_costs: result_statistic.dateDistribObj[date_key].company.costs + result_statistic.dateDistribObj[date_key].person.costs,
                    all_count: result_statistic.dateDistribObj[date_key].company.count + result_statistic.dateDistribObj[date_key].person.count
                } })

                diagram.push(arrData);

                ay_info = type_elem[2];
                line_info = type_elem[1];
                
            }


            const res_multi_deep = Object.keys(result_statistic).map((res_name) =><div><div>{res_name}</div><div>{ return_el.map( (key_el) => <div><div>{key_el.label}</div><div>{ result_statistic[res_name][key_el.key]}</div></div> ) }</div></div> );

            const pi_chart = diagram.map( dia_data =><PieChart width={430} height={250} >
                <Pie 
                    data={dia_data}
                    activeIndex={1}  
                    dataKey="value" 
                    cx="50%" 
                    cy="50%" 
                    outerRadius={80}
                    paddingAngle={5} 
                    fill="#82ca9d" 
                    label={renderCustomizedLabel}>
                        {dia_data.map( (entry, index) =><Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/> )}
                </Pie> 
            </PieChart> );

            


            return  (   <div>
                            { return_el.length > 0 ? <div>
                                 <div className={classes.diagramm} >{label}</div> 

                                 {diagram.length > 1 ?

                                 <Grid className={classes.grid_diag} container spacing={3} alignItems="baseline" >
{ diagram.map( dia_v => <Grid item xs={6}><DiagType data={dia_v} ay_info={ay_info} line_info={line_info} /></Grid>) 
                                 }</Grid> : <DiagType data={diagram[0]} ay_info={ay_info} line_info={line_info} /> }
                                </div>:
                                <Grid className={classes.grid} container spacing={3} alignItems="baseline" >
                                    <Grid item xs={3}>
                                        <Typography variant="h4" component="h5" className={classes.resultGrid} ><CounterAnimated number={result_statistic} unit={el.unut_name}/></Typography>
                                    </Grid>
                                    <Grid className={classes.gridItems} item xs={9}>{label}</Grid>
                                </Grid>
                                }
                        </div>
                     ) 
        }) }
       
        
                
        </div>
    )

}