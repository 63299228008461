const params = require('./../../../conf/api.json');

const paramApi = params.paramApi;

let isFetching = false;


const url_stat_kv = "/api/stat_all_part/";


export async function get_info_stat(year){

    const result = {};

    for(let yy = year - 1; yy <= year; yy++){
        result[yy] = {};
        for(let kv = 1; kv <= 4; kv++){
            const data = await get_stat_kv(`${kv}_${yy}`);
            //result[yy][kv] = {accepts: data.rep_accepts, progress: Math.trunc(data.rep_accepts/(data.rep_accepts + data.rep_unsend) * 100 ) };
            result[yy][kv] = {finished: data.rep_finished, accepts: data.rep_accepts};
        }
    }

      result[year -1][5] = {finished: 0, accepts: 0};

      [1,2,3,4].forEach(kv=>{ result[year -1][5]['finished'] += result[year -1][kv]['finished'];  result[year -1][5]['accepts'] += result[year -1][kv]['accepts'];} );

    return result;

}


async function get_stat_kv(kv){

    paramApi.method = "GET";

    if( isFetching ) return [];
    isFetching =  true;

    const result = await fetch(`${url_stat_kv}${kv}`, Object.assign({}, paramApi) );
    const data = await result.json();

    isFetching =  false;

    return data;
} 