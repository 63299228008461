export function countOfLocalOrganization(data) {
    const array_obj = data.tablets1;
    const result = array_obj.length;
    return result;
}

export function countOfCorporativeOrganization(data) {
    return data.tablets2.length;
}

export function countOfRunObjects(data) {
    return data.propertyMovables.length;
}

export function costOfRunObjectsCosts(data) {
    const array_obj = data.propertyMovables;
    const reducer = (acc, cur) => acc + (+cur.movables3);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function typesCountOfRunObjects(data){
    const array_obj = data.propertyMovables;
    const ownersType = 'movables7', 
          typePremises = 'movables1', 
          fieldCosts = 'movables3';
    
    const  arrTypesObj = {};
    array_obj.forEach(el=>{
        if(!arrTypesObj[ el[ownersType] ] ) arrTypesObj[ el[ownersType] ] = {};
        if(!arrTypesObj[el[ownersType]][ el[typePremises]] ) arrTypesObj[ el[ownersType]][ el[typePremises] ] = { count: 0, costs: 0};
        arrTypesObj[el[ownersType]][ el[typePremises] ].count += 1;
        arrTypesObj[el[ownersType]][ el[typePremises] ].costs += +el[fieldCosts];
    });
    return arrTypesObj;
}

export function countOfTransportObjects(data) {
    return data.propertyTransport.length;
}

export function costOfTransportObjectsCosts(data) {
    const array_obj = data.propertyTransport;
    const reducer = (acc, cur) => acc + (+cur.transport3);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function typesCountOfTransportObjects(data){
    const array_obj = data.propertyTransport;
    const ownersType = 'transport8', 
          typePremises = 'transport1', 
          fieldCosts = 'transport3';
    
    const  arrTypesObj = {};
    array_obj.forEach(el=>{
        if(!arrTypesObj[ el[ownersType] ] ) arrTypesObj[ el[ownersType] ] = {};
        if(!arrTypesObj[el[ownersType]][ el[typePremises]] ) arrTypesObj[ el[ownersType]][ el[typePremises] ] = { count: 0, costs: 0};
        arrTypesObj[el[ownersType]][ el[typePremises] ].count += 1;
        arrTypesObj[el[ownersType]][ el[typePremises] ].costs += +el[fieldCosts];
    });
    return arrTypesObj;
}

export function countOfPapers(data) {
    return data.propertyPapers.length;
}

export function costOfPapers(data) {
    const array_obj = data.propertyPapers;
    const reducer = (acc, cur) => acc + (+cur.papers5);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function typesCountOfPapers(data){
    const array_obj = data.propertyPapers;
    const fieldEmitent = 'papers4', 
          typePremises = 'papers1', 
          fieldCosts = 'papers5',
          fieldCount = 'papers3';
    
    const  arrTypesObj = {};
    array_obj.forEach(el=>{
        if(!arrTypesObj[ el[fieldEmitent] ] ) arrTypesObj[ el[fieldEmitent] ] = {};
        if(!arrTypesObj[el[fieldEmitent]][ el[typePremises]] ) arrTypesObj[ el[fieldEmitent]][ el[typePremises] ] = { count: 0, costs: 0};
        arrTypesObj[el[fieldEmitent]][ el[typePremises] ].count += +el[fieldCount];
        arrTypesObj[el[fieldEmitent]][ el[typePremises] ].costs += +el[fieldCosts];
    });
    return arrTypesObj;
}

export function countOfMoney(data) {
    return data.propertyMoney.length;
}

export function costOfMoney(data) {
    const array_obj = data.propertyMoney;
    const reducer = (acc, cur) => acc + (+cur.money8);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function costOfMoneyRecive(data) {
    const array_obj = data.propertyMoney;
    const reducer = (acc, cur) => acc + (+cur.money6);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function costOfMoneyPay(data) {
    const array_obj = data.propertyMoney;
    const reducer = (acc, cur) => acc + (+cur.money7);
    const result = array_obj.reduce(reducer, 0);
    
    return result; 
}

export function countOfNoMoneyObjects(data) {
    const array_obj = data.propertyNoMoney;
    const result = array_obj.length;
    return result;
}

export function costOfNoMoneyObjects(data) {
    const array_obj = data.propertyNoMoney;
    const reducer = (acc, cur) => acc + (+cur.noMoney4);
    const result = array_obj.reduce(reducer, 0);
    return result;
}


export function typesCountOfNoMoneyObjects(data) {
    const array_obj = data.propertyNoMoney;
    const ownersType = 'noMoney6', 
          typePremises = 'noMoney1', 
          fieldCosts = 'noMoney4', 
          fieldCount = 'noMoney2';
    
    const  arrTypesObj = {};
    array_obj.forEach(el=>{
        if(!arrTypesObj[ el[ownersType] ] ) arrTypesObj[ el[ownersType] ] = {};
        if(!arrTypesObj[el[ownersType]][ el[typePremises]] ) arrTypesObj[ el[ownersType]][ el[typePremises] ] = { count: 0, costs: 0};
        arrTypesObj[el[ownersType]][ el[typePremises] ].count += +el[fieldCount];
        arrTypesObj[el[ownersType]][ el[typePremises] ].costs += +el[fieldCosts];
    });
    return arrTypesObj;
}




export function countOfObjects(data) {
    const array_obj = data.propertyObjects;
    const result = array_obj.length;
    return result;
}

export function costOfObjects(data) {
    const array_obj = data.propertyObjects;
    const reducer = (acc, cur) => acc + (+(cur.objects4 || 0 ));
    const result = array_obj.reduce(reducer, 0);
    return result;
}

export function spaceOfObjects(data) {
    const array_obj = data.propertyObjects;
    const reducer = (acc, cur) => acc + (+cur.objects5);
    const result = array_obj.reduce(reducer, 0);
    return result;
}

export function typesCountOfObjects(data) {
    const array_obj = data.propertyObjects;
    const ownersType = 'objects7', 
          typePremises = 'objects1', 
          fieldCosts = 'objects4', 
          fieldSpace = 'objects5';
    
    const  arrTypesObj = {};
    array_obj.forEach(el=>{
        if(!arrTypesObj[ el[ownersType] ] ) arrTypesObj[ el[ownersType] ] = {};
        if(!arrTypesObj[el[ownersType]][ el[typePremises]] ) arrTypesObj[ el[ownersType]][ el[typePremises] ] = { count: 0, costs: 0, space: 0};
        arrTypesObj[el[ownersType]][ el[typePremises] ].count += 1;
        arrTypesObj[el[ownersType]][ el[typePremises] ].costs += +el[fieldCosts];
        arrTypesObj[el[ownersType]][el[typePremises] ].space += +el[fieldSpace];
    });
    return arrTypesObj;
}

// Function Contribution
//CostContributions

export function countOfContributionCost(data) {

    const data_obj = data.contributionConMoney;

    return data_obj.length;
}

export function sumOfAllContribution(data) {

    const data_obj = data.contributionConMoney;

    const reducer = (acc, cur) => acc + ( + (cur.conMoney10 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averageContributionCost(data) {

    const data_obj = data.contributionConMoney;
    const sum = sumOfAllContribution(data);
    const count = countOfContributionCost(data);
    return sum/count;

}

export function countOfPersonContributionCost(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.contributionConMoney;

    const reducer = (acc, cur) => cur.conMoney3 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyContributionCost(data) {

    const check_name = "Юридична особа";

    const data_obj = data.contributionConMoney;

    const reducer = (acc, cur) => cur.conMoney3 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonContributionCost(data) {

    const data_obj = data.contributionConMoney;

    const check_name_person = "Фізична особа";

    const typeContribution = 'conMoney3',
          ipn = 'conMoney1';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyContributionCost(data){

    const data_obj = data.contributionConMoney;

    const check_name_company = "Юридична особа";

    const typeContribution = 'conMoney3',
          ipn = 'conMoney2';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function dispertionOfContributionCostByPeriod(data) {

    const data_obj = data.contributionConMoney;

    const check_name_company = "Юридична особа";

    const check_name_person = "Фізична особа";

    const dateField = 'conMoney9', 
          typeContribution = 'conMoney3', 
          fieldCosts = 'conMoney10', 
          fieldCostsRevers = 'conMoney11';

    const date_period_from = new Date(data.year, (data.period.replace(/\D+/, '') - 1) *3, 1);

    const date_period_to = date_period_from.getMonth() == 9 ? new Date(  date_period_from.getFullYear()+1, 0, 0 ) : new Date( date_period_from.getFullYear(), date_period_from.getMonth() + 3, 0 );



    const dateDistribObj = {};

    data_obj.forEach(el=>{



        if(!el[dateField] || !el[dateField].split('.')  || !(el[dateField].split('.').length === 3)) return;
        
        const date_arr = el[dateField].split('.');

        const timeStamp = new Date(date_arr[2], date_arr[1] - 1, date_arr[0] ).getTime();

        if(!dateDistribObj[timeStamp]) dateDistribObj[timeStamp] = { company: { costs: 0, count: 0, revers: 0 }, person: { costs: 0, count: 0, revers: 0 } };

        if( el[typeContribution] ===  check_name_company ) {
            dateDistribObj[timeStamp].company.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].company.count += 1;

        }

        if( el[typeContribution] ===  check_name_person ) {
            dateDistribObj[timeStamp].person.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].person.count += 1;
        }

    });


    return { date_period_from: date_period_from, date_period_to: date_period_to, dateDistribObj: dateDistribObj };
}


// Function Contribution
//OtherContributions

export function countOfContributionOtherCon(data) {

    const data_obj = data.contributionOtherCon;

    return data_obj.length;
}

export function sumOfAllOtherCon(data) {

    const data_obj = data.contributionOtherCon;

    const reducer = (acc, cur) => acc + ( +(cur.other10)  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averageContributionOtherCon(data) {

    const data_obj = data.contributionOtherCon;
    const sum = sumOfAllOtherCon(data);
    const count = countOfContributionOtherCon(data);
    return sum/count;

}

export function countOfPersonContributionOtherCon(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.contributionOtherCon;

    const reducer = (acc, cur) => cur.other4 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyContributionOtherCon(data) {

    const check_name = "Юридична особа";

    const data_obj = data.contributionOtherCon;

    const reducer = (acc, cur) => cur.other4 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonContributionOtherCon(data) {

    const data_obj = data.contributionOtherCon;

    const check_name_person = "Фізична особа";

    const typeContribution = 'other4',
          ipn = 'other2';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyContributionOtherCon(data){

    const data_obj = data.contributionOtherCon;

    const check_name_company = "Юридична особа";

    const typeContribution = 'other4',
          ipn = 'other3';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function dispertionOfContributionOtherConByPeriod(data) {

    const data_obj = data.contributionOtherCon;

    const check_name_company = "Юридична особа";

    const check_name_person = "Фізична особа";

    const dateField = 'other5', 
          typeContribution = 'other4', 
          fieldCosts = 'other9';

    const date_period_from = new Date(data.year, (data.period.replace(/\D+/, '') - 1) *3, 1);

    const date_period_to = date_period_from.getMonth() == 9 ? new Date(  date_period_from.getFullYear()+1, 0, 0 ) : new Date( date_period_from.getFullYear(), date_period_from.getMonth() + 3, 0 );



    const dateDistribObj = {};

    data_obj.forEach(el=>{

        if(!el[dateField] || !el[dateField].split('.')  || !(el[dateField].split('.').length === 3)) return;
        
        const date_arr = el[dateField].split('.');

        const timeStamp = new Date(date_arr[2], date_arr[1] - 1, date_arr[0] ).getTime();

        
        if(!dateDistribObj[timeStamp]) dateDistribObj[timeStamp] = { company: { costs: 0, count: 0 }, person: { costs: 0, count: 0 } };

        if( el[typeContribution] ===  check_name_company ) {
            dateDistribObj[timeStamp].company.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].company.count += 1;

        }

        if( el[typeContribution] ===  check_name_person ) {
            dateDistribObj[timeStamp].person.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].person.count += 1;
        }

    });





    return { date_period_from: date_period_from, date_period_to: date_period_to, dateDistribObj: dateDistribObj };
}


export function countOfContributionGov(data) {

    const data_obj = data.contributionCosts;

    return data_obj.length;
}

export function sumOfAllConGov(data) {

    const data_obj = data.contributionCosts;

    const reducer = (acc, cur) => acc + ( + (cur.costs5)  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averageConGov(data) {

    const data_obj = data.contributionCosts;
    const sum = sumOfAllConGov(data);
    const count = countOfContributionGov(data);
    return sum/count;

}

export function countOfContributionOtherCosts(data) {

    const data_obj = data.contributionOtherCosts;

    return data_obj.length;
}

export function sumOfAllOtherCosts(data) {

    const data_obj = data.contributionOtherCosts;

    const reducer = (acc, cur) => acc + ( + (cur.otherCosts10)  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averageContributionOtherCosts(data) {

    const data_obj = data.contributionOtherCosts;
    const sum = sumOfAllOtherCosts(data);
    const count = countOfContributionOtherCosts(data);
    return sum/count;

}

export function countOfPersonContributionOtherCosts(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.contributionOtherCosts;

    const reducer = (acc, cur) => cur.otherCosts4 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyContributionOtherCosts(data) {

    const check_name = "Юридична особа";

    const data_obj = data.contributionOtherCosts;

    const reducer = (acc, cur) => cur.otherCosts4 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonContributionOtherCosts(data) {

    const data_obj = data.contributionOtherCosts;

    const check_name_person = "Фізична особа";

    const typeContribution = 'otherCosts4',
          ipn = 'otherCosts3';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyContributionOtherCosts(data){

    const data_obj = data.contributionOtherCosts;

    const check_name_company = "Юридична особа";

    const typeContribution = 'otherCosts4',
          ipn = 'otherCosts3';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}


// Function PaymentGov
//

export function countOfPaymentGov(data) {

    const data_obj = data.paymentGov;

    return data_obj.length;
}

export function sumOfAllPaymentGov(data) {

    const data_obj = data.paymentGov;

    const reducer = (acc, cur) => acc + ( + (cur.paymentGov12 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averagePaymentGov(data) {

    const sum = sumOfAllPaymentGov(data);
    const count = countOfPaymentGov(data);
    return sum/count;

}

export function countOfPersonPaymentGov(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.paymentGov;

    const reducer = (acc, cur) => cur.paymentGov13 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyPaymentGov(data) {

    const check_name = "Юридична особа";

    const data_obj = data.paymentGov;

    const reducer = (acc, cur) => cur.paymentGov13 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonPaymentGov(data) {

    const data_obj = data.paymentGov;

    const check_name_person = "Фізична особа";

    const typeContribution = 'paymentGov13',
          ipn = 'paymentGov8';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyPaymentGov(data){

    const data_obj = data.paymentGov;

    const check_name_company = "Юридична особа";

    const typeContribution = 'paymentGov13',
          ipn = 'paymentGov9';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function dispertionOfPaymentGovByPeriod(data) {

    const data_obj = data.paymentGov;

    const dateField = 'paymentGov6', 
          typeContribution = 'paymentGov13', 
          fieldCosts = 'paymentGov12';
    
    const {period, year} = data;

    return dispertionLineBase(data_obj, dateField, typeContribution, fieldCosts, period, year);

}

// Function PaymentCostsPaymentReceive
//

export function countOfPaymentCostsPaymentReceive(data) {

    const data_obj = data.paymentCostsPaymentReceive;

    return data_obj.length;
}

export function sumOfAllPaymentCostsPaymentReceive(data) {

    const data_obj = data.paymentCostsPaymentReceive;

    const reducer = (acc, cur) => acc + ( + (cur.costs12 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function sumOfAllPaymentCostsPaymentReceiveGov(data) {

    const data_obj = data.paymentCostsPaymentReceive;

    const reducer = (acc, cur) => acc + ( + (cur.costs13 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averagePaymentCostsPaymentReceive(data) {

    const sum = sumOfAllPaymentCostsPaymentReceive(data);
    const count = countOfPaymentCostsPaymentReceive(data);
    return sum/count;

}

export function countOfPersonPaymentCostsPaymentReceive(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.paymentCostsPaymentReceive;

    const reducer = (acc, cur) => cur.costs14 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyPaymentCostsPaymentReceive(data) {

    const check_name = "Юридична особа";

    const data_obj = data.paymentCostsPaymentReceive;

    const reducer = (acc, cur) => cur.costs14 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonPaymentCostsPaymentReceive(data) {

    const data_obj = data.paymentCostsPaymentReceive;

    const check_name_person = "Фізична особа";

    const typeContribution = 'costs14',
          ipn = 'costs5';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyPaymentCostsPaymentReceive(data){

    const data_obj = data.paymentCostsPaymentReceive;

    const check_name_company = "Юридична особа";

    const typeContribution = 'costs14',
          ipn = 'costs5';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

// Function PaymentOtherCostsPaymentReceive
//

export function countOfPaymentOtherCostsPaymentReceive(data) {

    const data_obj = data.paymentOtherCostsPaymentReceive;

    return data_obj.length;
}

export function sumOfAllPaymentOtherCostsPaymentReceive(data) {

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const reducer = (acc, cur) => acc + ( + (cur.otherCosts11 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function sumOfAllPaymentOtherCostsPaymentReceiveGov(data) {

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const reducer = (acc, cur) => acc + ( + (cur.otherCosts12 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}


export function averagePaymentOtherCostsPaymentReceive(data) {

    const sum = sumOfAllPaymentOtherCostsPaymentReceive(data);
    const count = countOfPaymentOtherCostsPaymentReceive(data);
    return sum/count;

}

export function countOfPersonOtherPaymentCostsPaymentReceive(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const reducer = (acc, cur) => cur.otherCosts14 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyPaymentOtherCostsPaymentReceive(data) {

    const check_name = "Юридична особа";

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const reducer = (acc, cur) => cur.otherCosts14 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonPaymentOtherCostsPaymentReceive(data) {

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const check_name_person = "Фізична особа";

    const typeContribution = 'otherCosts14',
          ipn = 'otherCosts8';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyPaymentOtherCostsPaymentReceive(data){

    const data_obj = data.paymentOtherCostsPaymentReceive;

    const check_name_company = "Юридична особа";

    const typeContribution = 'otherCosts14',
          ipn = 'otherCosts8';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

// Function obligate
//

export function countOfObligate(data) {

    const data_obj = data.obligate;

    return data_obj.length;
}

export function sumOfAllObligate(data) {

    const data_obj = data.obligate;

    const reducer = (acc, cur) => acc+cur.obligates8;

    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function averageObligate(data) {

    const sum = sumOfAllObligate(data);
    const count = countOfObligate(data);
    return sum/count;

}

export function countOfPersonObligate(data) {
    
    const check_name = "Фізична особа";

    const data_obj = data.obligate;

    const reducer = (acc, cur) => cur.obligates9 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfCompanyObligate(data) {

    const check_name = "Юридична особа";

    const data_obj = data.obligate;

    const reducer = (acc, cur) => cur.obligates9 === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfUniqPersonObligate(data) {

    const data_obj = data.obligate;

    const check_name_person = "Фізична особа";

    const typeContribution = 'obligates9',
          ipn = 'obligates4';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_person) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqCompanyObligate(data){

    const data_obj = data.obligate;

    const check_name_company = "Юридична особа";

    const typeContribution = 'obligates9',
          ipn = 'obligates5';

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[typeContribution] === check_name_company) {
            if(!person_uniq_obj[el[ipn]]) person_uniq_obj[el[ipn]] = 0;
            person_uniq_obj[el[ipn]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

// Function PaymentOther
//CostContributions

export function countOfPaymentOtherCost(data) {

    const data_obj = data.paymentOther;

    return data_obj.length;
}

export function sumOfAllPaymentOther(data) {

    const data_obj = data.paymentOther;

    const reducer = (acc, cur) => acc + ( + (cur.other7 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function sumOfAllPaymentOther_2020(data) {

    const data_obj = data.paymentOther;

    const reducer = (acc, cur) => acc + ( + (cur.other12 )  );
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function averagePaymentOtherCost(data) {

    const sum = sumOfAllPaymentOther(data);
    const count = countOfPaymentOtherCost(data);
    return sum/count;

}

export function averagePaymentOtherCost_2020(data) {

    const sum = sumOfAllPaymentOther_2020(data);
    const count = countOfPaymentOtherCost(data);
    return sum/count;

}

export function countOfPersonPaymentOtherCost_2020(data) {
    const key = "other8";
    const data_obj = data.paymentOther;
    return countOfPerson_base(data_obj, key);
}

function countOfPerson_base(data_obj, key) {
    
    const check_name = "Фізична особа";

    return countPerCom(data_obj, key, check_name);
    
}

function countOfCompany_base(data_obj, key) {
    
    const check_name = "Юридична особа";

    return countPerCom(data_obj, key, check_name);
    
}

function countPerCom(data_obj, key, check_name) {

    const reducer = (acc, cur) => cur[key] === check_name ? acc + 1: acc;
    const result = data_obj.reduce(reducer, 0);

    return result;
}

export function countOfPersonPaymentOtherCost(data) {

    const key = "other12";
    const data_obj = data.paymentOther;
    return countOfPerson_base(data_obj, key);
    
}

export function countOfCompanyPaymentOtherCost(data) {

    const data_obj = data.paymentOther;

    const key = "other12";

    return countOfCompany_base(data_obj,key);
}

export function countOfCompanyPaymentOtherCost_2020(data) {

    const data_obj = data.paymentOther;

    const key = "other8";

    return countOfCompany_base(data_obj,key);
}


function countOfUniq_base(data_obj, fieldTypePerson, typePerson, key) {

    const person_uniq_obj = {};

    data_obj.forEach(el=>{
        if(el[fieldTypePerson] === typePerson) {
            if(!person_uniq_obj[el[key]]) person_uniq_obj[el[key]] = 0;
            person_uniq_obj[el[key]] +=1; 
        }
    });

    return Object.keys(person_uniq_obj).length;

}

export function countOfUniqPersonPaymentOtherCost(data) {

    const data_obj = data.paymentOther;

    const check_name_person = "Фізична особа";

    const typeContribution = 'other12',
          ipn = 'other3';

    return   countOfUniq_base(data_obj,typeContribution, check_name_person, ipn);
}


export function countOfUniqPersonPaymentOtherCost_2020(data) {

    const data_obj = data.paymentOther;

    const check_name_person = "Фізична особа";

    const typeContribution = 'other8',
          ipn = 'other7';

    return   countOfUniq_base(data_obj,typeContribution, check_name_person, ipn);
}

export function countOfUniqCompanyPaymentOtherCost(data){

    const data_obj = data.paymentOther;

    const check_name_company = "Юридична особа";

    const typeContribution = 'other12',
          ipn = 'other3';

    return   countOfUniq_base(data_obj,typeContribution, check_name_company, ipn);

}

export function countOfUniqCompanyPaymentOtherCost_2020(data){

    const data_obj = data.paymentOther;

    const check_name_company = "Юридична особа";

    const typeContribution = 'other8',
          ipn = 'other7';

    return   countOfUniq_base(data_obj,typeContribution, check_name_company, ipn);

}

export function dispertionOfPaymentOtherCostByPeriod(data) {

    const data_obj = data.paymentOther;

    const dateField = 'other1', 
          typeContribution = 'other12', 
          fieldCosts = 'other7';
    
    const {period, year} = data;

    return dispertionLineBase(data_obj, dateField, typeContribution, fieldCosts, period, year);

}

export function dispertionOfPaymentOtherCostByPeriod_2020(data) {

    const data_obj = data.paymentOther;

    const dateField = 'other5', 
          typeContribution = 'other8', 
          fieldCosts = 'other12';    

    const {period, year} = data;

    return dispertionLineBase(data_obj, dateField, typeContribution, fieldCosts, period, year);

}

//== Elections formulas

//General Formulas

function reduceArraySum(data, array, field) {
    const array_obj = data[array];
    const reducer = (acc, cur) => acc + (+cur[field]);
    const result = array_obj.reduce(reducer, 0);
    return result;
}

//--Block1
export function countOfMoneyEl1(data) {
    return data.moneyElectionFundAccounts.length;
}

export function costOfMoneyEl1(data) {
    return reduceArraySum(data, 'moneyElectionFundAccounts', 'leftMoney');
}

export function costOfMoneyReciveEl1(data) {
    return reduceArraySum(data, 'moneyElectionFundAccounts', 'totalSum'); 
}

export function costOfMoneyPayEl1(data) {
    return reduceArraySum(data, 'moneyElectionFundAccounts', 'totalUsed');  
}

//--Block2
export function countOfMoneyEl2(data) {
    return data.contributionOwnCandidateMoney.length;
}

export function costOfMoneyEl2(data) {
    return reduceArraySum(data, 'contributionOwnCandidateMoney', 'sum');
}

//--Block3
export function countOfMoneyEl3(data) {
    return data.contributionVoluntaryFizUr.length;
}

export function costOfMoneyEl3(data) {
    return reduceArraySum(data, 'contributionVoluntaryFizUr', 'sum');
}

//--Block4
export function countOfMoneyEl4(data) {
    return data.contributionWrong.length;
}

export function costOfMoneyEl4(data) {
    return reduceArraySum(data, 'contributionWrong', 'sum');
}

//--Block5
export function countOfMoneyEl5(data) {
    return data.receivingOnCurrentAccounts.length;
}

export function costOfMoneyEl5(data) {
    return reduceArraySum(data, 'receivingOnCurrentAccounts', 'sum');
}

//--Block6
export function countOfMoneyEl6(data) {
    return data.transferMoneyFromAccumulationAccount.length;
}

export function costOfMoneyEl6(data) {
    return reduceArraySum(data, 'transferMoneyFromAccumulationAccount', 'sum');
}

//--Block7
export function countOfMoneyEl7(data) {
    return data.refundsMoneyFromCurrentAccount.length;
}

export function costOfMoneyEl7(data) {
    return reduceArraySum(data, 'refundsMoneyFromCurrentAccount', 'sum');
}

//--Block8
export function countOfMoneyEl8(data) {
    return data.getMoneyFromVolunteerContributorFizUr.length;
}

export function costOfMoneyEl8(data) {
    return reduceArraySum(data, 'getMoneyFromVolunteerContributorFizUr', 'sum');
}

//--Block9
export function countOfMoneyEl9(data) {
    return data.refundsMoneyFromVolunteerContributorFizUr.length;
}

export function costOfMoneyEl9(data) {
    return reduceArraySum(data, 'refundsMoneyFromVolunteerContributorFizUr', 'sum');
}

//--Block10
export function countOfMoneyEl10(data) {
    return data.paymentsFromElectionFoundAccounts.length;
}

export function costOfMoneyEl10(data) {
    return reduceArraySum(data, 'paymentsFromElectionFoundAccounts', 'sum');
}


function dispertionLineBase(data_obj, dateField, typeContribution, fieldCosts, period, year) {

    const check_name_company = "Юридична особа",
          check_name_person = "Фізична особа";
    
    const date_period_from = new Date(year, (period.replace(/\D+/, '') - 1) *3, 1);

    const date_period_to = date_period_from.getMonth() == 9 ? new Date(  date_period_from.getFullYear()+1, 0, 0 ) : new Date( date_period_from.getFullYear(), date_period_from.getMonth() + 3, 0 );


    const dateDistribObj = {};

    data_obj.forEach(el=>{

        if(!el[dateField] || !el[dateField].split('.')  || !(el[dateField].split('.').length === 3)) return;
        
        const date_arr = el[dateField].split('.');

        const timeStamp = new Date(date_arr[2], date_arr[1] - 1, date_arr[0] ).getTime();

        if(!dateDistribObj[timeStamp]) dateDistribObj[timeStamp] = { company: { costs: 0, count: 0, revers: 0 }, person: { costs: 0, count: 0, revers: 0 } };

        if( el[typeContribution] ===  check_name_company ) {
            dateDistribObj[timeStamp].company.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].company.count += 1;

        }

        if( el[typeContribution] ===  check_name_person ) {
            dateDistribObj[timeStamp].person.costs += +el[fieldCosts];//.replace(/\s/g, '').replace(',', '.');
            dateDistribObj[timeStamp].person.count += 1;
        }

    });


    return { date_period_from: date_period_from, date_period_to: date_period_to, dateDistribObj: dateDistribObj };

}